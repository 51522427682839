import React, { JSX, Suspense, useEffect, useState } from "react"

import { PreviewCard } from "@base-ui-components/react/preview-card"
import stylex from "@stylexjs/stylex"

export interface PopoverProps extends React.PropsWithChildren {
  render: () => React.ReactNode
  children: JSX.Element
  disabled?: boolean
}

const styles = stylex.create({
  animates: {
    transformOrigin: `var(--transform-origin)`,
    transition: `transform 150ms, opacity 150ms;`,
    ":is([data-starting-style])": {
      transform: `scale(0.9)`,
      opacity: 0,
    },
  },
  isNotSuspended: {
    opacity: 1,
    transform: `scale(1)`,
  },
})

export const HoverPopover = (props: PopoverProps) => {
  const { disabled, children, render } = props
  const [suspended, setSuspended] = useState(false)

  if (disabled) return children

  return (
    <PreviewCard.Root delay={0} closeDelay={100}>
      <PreviewCard.Trigger render={<span />} style={{ opacity: suspended ? 0.5 : 1 }}>
        {children}
      </PreviewCard.Trigger>
      <PreviewCard.Portal>
        <PreviewCard.Positioner sideOffset={10}>
          <PreviewCard.Popup {...stylex.props([styles.animates, !suspended && styles.isNotSuspended])}>
            <Suspense fallback={<SetTrueWhenRendering setValue={setSuspended} />}>{render()}</Suspense>
          </PreviewCard.Popup>
        </PreviewCard.Positioner>
      </PreviewCard.Portal>
    </PreviewCard.Root>
  )
}

// Suspense only works with react components, so this "converts" the suspense boundary API to a hook
const SetTrueWhenRendering = (props: { setValue: (v: boolean) => void }) => {
  useEffect(() => {
    props.setValue(true)
    return () => props.setValue(false)
  }, [props])
  return null
}
