import { SecondaryText } from "@components/DesignSystem"
import { TodayLink } from "@components/TodayLink"
import stylex from "@stylexjs/stylex"

import { useGetPartner } from "../../CoreContext"
import { LogoWithAnimation } from "../LogoWithAnimation"
import { OrtaReference } from "../OrtaReference"
import { ZachReference } from "../ZachReference"

const styles = stylex.create({
  logoSubtitle: {
    color: "var(--theme-alwaysLight)",
    whiteSpace: "pre",
    marginBottom: 0,
  },
})

export const DefaultLogo = () => {
  const partner = useGetPartner()
  const bgColorKey = partner?.navBG === "Key" ? "key" : partner?.navBG === "Light" ? "alwaysLight" : "alwaysDark"
  return (
    <TodayLink
      renderDisplay={() => (
        <>
          <LogoWithAnimation width={160} backgroundColor={`var(--theme-${bgColorKey}`} />
          <SecondaryText stylexStyle={styles.logoSubtitle}>
            <span>by </span>
            <OrtaReference short />, <ZachReference short /> & Friends
          </SecondaryText>
        </>
      )}
    />
  )
}
