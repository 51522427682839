import { useState } from "react"
import { graphql, useFragment } from "react-relay"

import { BodyText, H2, H3, InternalLink, ThinButton, UserTagText } from "@components/DesignSystem"
import { ModalDisplay } from "@components/ModalDisplay"
import { UserDisplayPopoverContent } from "@components/profiles/UserDisplayPopover"
import { radius, spacing } from "@palette/tokens.stylex"
import { FriendsSection$key } from "@relay/FriendsSection.graphql"
import stylex from "@stylexjs/stylex"

import { socialSectionHeaderStyles } from "../../screens/SocialScreen"
import { FriendSearch } from "./FriendsSearch"

const FriendsFragment = graphql`
  fragment FriendsSection on NakamaFriendsList {
    friends {
      user {
        puzmoUser {
          relationshipToViewer
          tagFromViewer
          id
          username
          usernameID

          publicProfile {
            highlightedStats {
              slug
            }
          }
        }
      }
    }
  }
`

export const FriendsSection = (props: { friends: FriendsSection$key }) => {
  const friendsData = useFragment(FriendsFragment, props.friends)?.friends

  const [showingSearch, setShowingSearch] = useState(false)
  const toggleSearch = () => setShowingSearch((s) => !s)

  const orderedFriendsData = (friendsData || []).filter((f) => f.user.puzmoUser && f.user.puzmoUser.relationshipToViewer === "Friends")
  orderedFriendsData.sort((a, b) => {
    const aHighlights = a.user.puzmoUser!.publicProfile?.highlightedStats || []
    const bHighlights = b.user.puzmoUser!.publicProfile?.highlightedStats || []

    return bHighlights.length - aHighlights.length
  })

  return (
    <>
      <div id="friends">
        <div {...stylex.props(socialSectionHeaderStyles.header)}>
          <H2 marginLess>Friends</H2>
          <div {...stylex.props(socialSectionHeaderStyles.headerButtons)}>
            <ThinButton
              title="Find friend"
              bgKey="key"
              colorKey="keyFG"
              onPress={toggleSearch}
              block
              stylexStyle={socialSectionHeaderStyles.headerButton}
            />
          </div>
        </div>
        <div {...stylex.props(styles.friendsList)}>
          {!orderedFriendsData.length && <BodyText>You haven't added any friends yet.</BodyText>}
          {orderedFriendsData.map(({ user }, i) => {
            const pUser = user.puzmoUser
            if (!pUser) return null

            return (
              <div {...stylex.props(styles.friend)}>
                <div key={pUser.id} {...stylex.props(styles.profileCardWrapper)}>
                  <InternalLink screen="UserProfile" routeOpts={{ username: pUser.username, usernameID: pUser.usernameID }}>
                    <UserDisplayPopoverContent key={pUser.id} id={pUser.id} />
                  </InternalLink>
                </div>
                {pUser.tagFromViewer ? (
                  <BodyText>
                    Your <UserTagText>{pUser.tagFromViewer?.toUpperCase()}</UserTagText>
                  </BodyText>
                ) : (
                  // This is kind of a hack to keep the bottom of the profile cards aligned when some have tags and some don't.
                  <UserTagPlaceholder />
                )}
              </div>
            )
          })}
        </div>
      </div>
      <FriendSearchModal show={showingSearch} onClose={toggleSearch} />
    </>
  )
}

const UserTagPlaceholder = () => (
  <BodyText stylexStyle={styles.emptyTag}>
    <UserTagText></UserTagText>
  </BodyText>
)

const FriendSearchModal = (props: { show: boolean; onClose: () => void }) => {
  return (
    <ModalDisplay
      show={props.show}
      close={props.onClose}
      modal={
        <div {...stylex.props(styles.friendSearchModal)}>
          <H3>Find a friend</H3>
          <br />
          <FriendSearch goal="friends" />
        </div>
      }
    />
  )
}

const styles = stylex.create({
  friendsList: {
    paddingVertical: spacing.large,
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(272px, 1fr))",
    gap: spacing.large,
  },
  friend: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.medium,
    alignItems: "center",
  },
  profileCardWrapper: {
    backgroundColor: "var(--theme-a_bgAlt)",
    display: "flex",
    flex: 1,
    flexDirection: "column-reverse",
    borderRadius: radius.small,
  },
  emptyTag: {
    visibility: "hidden",
  },
  friendSearchModal: {
    padding: spacing.large,
    overflowY: "auto",
  },
})
