import { useState } from "react"
import { useForm } from "react-hook-form"
import { graphql, useMutation } from "react-relay"

import { PuzInput } from "@components/forms/PuzForm"
import { spacing } from "@palette/tokens.stylex"
import { slugify } from "@puzzmo-com/shared/slugify"
import { CreateClubFormMutation } from "@relay/CreateClubFormMutation.graphql"
import stylex from "@stylexjs/stylex"

import { useGetUser } from "../../CoreContext"
import { BodyText, Gap, H3, PuzmoButton } from "../../components/DesignSystem"

export const CreateClubForm = (props: { onClose: () => void; refreshClubs: () => void }) => {
  const [errorMessage, setErrorMessage] = useState("")
  const [created, setCreated] = useState(false)

  const form = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  })
  const formID = "create-club"
  const name = form.watch("name")
  const description = form.watch("description")

  const [create, loading] = useMutation<CreateClubFormMutation>(graphql`
    mutation CreateClubFormMutation($input: UpdateGroupInput!) {
      createGroup(input: $input) {
        ... on NakamaGroup {
          id
          slug
          slugID
        }
        ... on ErrorableResponse {
          message
          success
        }
      }
    }
  `)

  const onSubmit = (data: any) => {
    create({
      variables: {
        input: {
          ...data,
          slug: slugify(data.name),
          open: false,
        },
      },
      onError(error) {
        console.error("error", error)
      },
      onCompleted: (res) => {
        if (!res.createGroup.success && res.createGroup.message) {
          setErrorMessage(res.createGroup.message)
        } else if (res.createGroup) {
          setCreated(true)
          props.refreshClubs()
        }
      },
    })
  }

  const canSubmit = !!name.length && !!description.length

  if (created) {
    return (
      <div {...stylex.props(styles.wrapper)}>
        <H3 marginLess>Create a new club</H3>
        <BodyText>Club "{name}" successfully created!</BodyText>
        <div {...stylex.props(styles.buttonsWrapper)}>
          <PuzmoButton block type="secondary" colorKey="alwaysLight" title="Done" onPress={props.onClose} disabled={loading} />
        </div>
      </div>
    )
  }

  return (
    <div {...stylex.props(styles.wrapper)}>
      <H3 marginLess>Create a new club</H3>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <PuzInput name="name" label="Name" formID={formID} form={form} />
        <PuzInput name="description" label="Description" formID={formID} form={form} />

        {!!errorMessage && <BodyText customColorKey="error">{errorMessage}</BodyText>}

        <div {...stylex.props(styles.buttonsWrapper)}>
          <Gap direction="horizontal">
            <PuzmoButton block type="secondary" colorKey="alwaysLight" title="Cancel" onPress={props.onClose} disabled={loading} />
            <PuzmoButton
              block
              bgKey="key"
              colorKey="keyFG"
              title={loading ? "Creating…" : "Create club"}
              onPress={form.handleSubmit(onSubmit)}
              disabled={loading || !canSubmit}
            />
          </Gap>
        </div>
      </form>
    </div>
  )
}

const styles = stylex.create({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: spacing.small,
  },
  buttonsWrapper: {
    marginTop: spacing.large,
    marginBottom: spacing.small,
  },
})
