import { View } from "react-native"

import { AnimatedPressableBase, InlineText } from "@components/DesignSystem"
import { InviteIcon } from "@components/icons/InviteIcon"
import { radius, spacing } from "@palette/tokens.stylex"
import stylex from "@stylexjs/stylex"

import { useCopyGameURL } from "../hooks/useCopyGameURL"
import { usePlayGameSelector } from "../lib/playGameContext/usePlayGameSelector"
import { useGetNavigationHeaderViewModel } from "../navigation/NavigationHeaderViewModel"

export function InviteButton(props: { align?: "vertical" | "horizontal" }) {
  const gameSlug = usePlayGameSelector((s) => s.gameUIState.gameSlug)
  const gameplaySlug = usePlayGameSelector((s) => s.gameUIState.playGameSlug)
  const [copyURL, didCopy] = useCopyGameURL(gameSlug, gameplaySlug)
  const navigationHeaderViewModel = useGetNavigationHeaderViewModel()

  return (
    <AnimatedPressableBase
      onPress={copyURL}
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
      stylexStyle={[styles.pressableBase, props.align === "horizontal" ? styles.horizontal : styles.vertical]}
    >
      <div {...stylex.props(styles.iconWrapper)}>
        <InviteIcon width={24} color="alwaysLight" />
      </div>
      <InlineText
        style={{
          opacity: didCopy ? 0.66 : 1,
        }}
        customColorKey={navigationHeaderViewModel.navigationForegroundAppearance}
        size="small"
        bold
      >
        {didCopy ? "Copied!" : "Invite"}
      </InlineText>
    </AnimatedPressableBase>
  )
}

const styles = stylex.create({
  pressableBase: {
    borderRadius: radius.small,
    padding: spacing.small,
    ":hover": {
      backgroundColor: "var(--theme-alwaysLight-a10)",
    },
  },
  horizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: spacing.small,
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: spacing.xsmall,
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 14,
    overflow: "visible",
    position: "relative",
  },
})
