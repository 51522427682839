import stylex from "@stylexjs/stylex"

import { basicStyles } from "./styles"
import { spacing } from "./tokens.stylex"
import { CustomColorKey, StylableElementProps } from "./types"

interface HRProps extends StylableElementProps {
  marginLess?: true
  fullWidth?: true
  colorKey?: CustomColorKey
  thick?: true
}

const HRStyles = stylex.create({
  base: (colorVar: string) => ({
    marginTop: spacing.small,
    marginBottom: spacing.medium,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: colorVar,
  }),
  thick: {
    borderBottomWidth: "2px",
  },
  dashed: {
    marginTop: spacing.medium,
    borderBottomStyle: "dashed",
  },
})

export const HR = ({ marginLess, thick, fullWidth, colorKey, stylexStyle, style }: HRProps) => {
  const colorVar = `var(--theme-${colorKey || "a_headerText-a50"})`
  return (
    <div
      {...stylex.props(
        HRStyles.base(colorVar),
        marginLess && basicStyles.marginLess,
        fullWidth && basicStyles.fullWidth,
        thick && HRStyles.thick,
        stylexStyle,
        style as any,
      )}
    />
  )
}

export const DashedHR = ({ marginLess, thick, fullWidth, colorKey, stylexStyle, style }: HRProps) => {
  const colorVar = `var(--theme-${colorKey || "a_headerText"})`
  return (
    <div
      {...stylex.props(
        HRStyles.base(colorVar),
        HRStyles.dashed,
        marginLess && basicStyles.marginLess,
        fullWidth && basicStyles.fullWidth,
        thick && HRStyles.thick,
        stylexStyle,
        style as any,
      )}
    />
  )
}
