import { useContext } from "react"

import { ClubsIcon } from "@components/icons/ClubsIcon"
import { ProfileIcon } from "@components/icons/ProfileIcon"
import { faceoffHeaderHeight } from "@consts/constants"
import stylex from "@stylexjs/stylex"

import { useIsNarrow } from "../../hooks/useIsNarrow"
import { spacing } from "../../palette/tokens.stylex"
import { Alerts } from "../Alerts"
import { useGetNavigationHeaderViewModel } from "../NavigationHeaderViewModel"
import { PlayerDropdown } from "../PlayerDropdown"
import { AppHeaderTab } from "./AppHeaderTab"
import { DefaultLogo } from "./DefaultLogo"
import { HeaderContext } from "./HeaderContext"

const styles = stylex.create({
  headerContainer: (color: string) => ({
    position: "sticky",
    top: 0,
    backgroundColor: color,
    zIndex: 2,
    height: faceoffHeaderHeight,
  }),
  header: {
    height: "100%",
    position: "relative",
    overflow: "hidden",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  sectionContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  leftContainer: {
    marginLeft: spacing.large,
  },
  logoSubtitle: {
    color: "var(--theme-alwaysLight)",
    whiteSpace: "pre",
    marginBottom: 0,
  },
  rightContainer: {
    marginRight: spacing.large,
  },
  renderInto: {
    height: "100%",
  },
})

export function DesktopHeaderFaceoff() {
  const viewModel = useGetNavigationHeaderViewModel()
  return (
    <div {...stylex.props(styles.headerContainer(viewModel.navigationBackgroundColor))} role="navigation" aria-label="Main">
      <div {...stylex.props(styles.header)}>
        <DesktopLeft />
        <DesktopRight />
      </div>
      <Alerts />
    </div>
  )
}

const DesktopRight = () => {
  const headerContext = useContext(HeaderContext)
  const isNarrow = useIsNarrow()

  return (
    <div {...stylex.props([styles.sectionContainer, styles.rightContainer])}>
      <div ref={headerContext.rightHandContainerEl} {...stylex.props(styles.renderInto)}></div>
      <AppHeaderTab
        routeName="Social"
        text="Clubs & Friends"
        iconComponent={<ClubsIcon width={25} fgColor="alwaysLight" bgColor="alwaysDark" />}
        smallWidth={headerContext.portalState.rightItems || isNarrow}
      />
      <AppHeaderTab
        routeName="UserProfileSettings"
        text="Your Profile"
        iconComponent={<ProfileIcon width={25} fill="alwaysLight" />}
        smallWidth={headerContext.portalState.rightItems || isNarrow}
      />
      <PlayerDropdown />
    </div>
  )
}

const DesktopLeft = () => {
  const headerContext = useContext(HeaderContext)
  return (
    <div {...stylex.props([styles.sectionContainer, styles.leftContainer])}>
      <div ref={headerContext.logoContainerEl}>{headerContext.portalState.logo ? null : <DefaultLogo />}</div>
      <div ref={headerContext.leftHandContainerEl} {...stylex.props(styles.renderInto)}></div>
    </div>
  )
}
