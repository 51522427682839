import { LinearGradient } from "expo-linear-gradient"
import React, {
  CSSProperties,
  Children,
  HTMLAttributeAnchorTarget,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import flattenChildren from "react-keyed-flatten-children"
import { ImageProps, Pressable, PressableProps, Text, TextProps, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { StyledProps, Theme, styled, useTheme } from "styled-rn"
import { Link } from "wouter"

import { basicStyles } from "@palette/styles"
import { CustomColorKey, StylableContainerProps } from "@palette/types"
import { Picker } from "@react-native-picker/picker"
import stylex, { StyleXStyles } from "@stylexjs/stylex"

import { AppContext, useAppContext } from "../../AppContext"
import { useGetUser } from "../CoreContext"
import { useIsMobile } from "../hooks/useIsMobile"
import { blendColors } from "../lib/blendColors"
import { useViewport } from "../lib/useViewport"
import { userIsAdmin } from "../lib/userUtils"
import { waitFor } from "../lib/waitFor"
import { RouteParameters, RouteQueryParams, namedRoutes } from "../navigation/LinkingConfiguration"
import { getPathForNavigationRoute } from "../navigation/useNavigateWithParams"
import { animDurations, fontFamilies, fontSizes, lineHeights, radius, spacing } from "../palette/tokens.stylex"
import { omit } from "../util/omit"
import { CheckMark } from "./icons/Checkmark"

export const BASE_PADDING = 12
export const BASE_MARGIN = 12
export const BASE_RADIUS = 5

export const PROSE_MAX_WIDTH = 550

// Breakpoint values should be kept in sync with the constants defined in Layout.ts
// We can't import them because of the way stylex works, and they can't be
// defined as variables because variables are not supported in media queries.
const mediaQueries = {
  mobile: "@media (max-width: 560px)",
}

export type HeaderProps = {
  marginLess?: true
  as?: "h1" | "h2" | "h3" | "h4" | "h5"
} & StylableContainerProps

const h2Styles = stylex.create({
  base: {
    overflowWrap: "break-word",
    fontSize: {
      default: fontSizes.h2FontSize,
      [mediaQueries.mobile]: fontSizes.h2FontSizeMobile,
    },
    lineHeight: lineHeights.fontSize,
    marginTop: spacing.large,
    marginBottom: spacing.small,
    color: "var(--theme-a_headerText)",
    display: "flex",
    fontWeight: 400,
  },
})

export const H2 = ({ marginLess, children, stylexStyle, style, as }: HeaderProps) => {
  const Tag = as || "h2"
  return (
    <Tag {...stylex.props(h2Styles.base, basicStyles.headingFont, marginLess && basicStyles.marginLess, stylexStyle, style as any)}>
      {children}
    </Tag>
  )
}

const h3Styles = stylex.create({
  base: {
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    fontSize: {
      default: fontSizes.h3FontSize,
      [mediaQueries.mobile]: fontSizes.h3FontSizeMobile,
    },
    lineHeight: lineHeights.fontSize,
    marginTop: spacing.medium,
    marginBottom: spacing.small,
    color: "var(--theme-a_headerText)",
    fontWeight: 400,
    display: "flex",
  },
})

export const H3 = ({ marginLess, children, stylexStyle, style, as, ref }: HeaderProps & { ref?: React.Ref<any> }) => {
  const Tag = as || "h3"
  return (
    <Tag
      {...stylex.props(h3Styles.base, basicStyles.headingFont, marginLess && basicStyles.marginLess, stylexStyle, style as any)}
      ref={ref}
    >
      {children}
    </Tag>
  )
}

const h4Styles = stylex.create({
  base: {
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    fontSize: {
      default: fontSizes.h4FontSize,
      [mediaQueries.mobile]: fontSizes.h4FontSizeMobile,
    },
    lineHeight: lineHeights.fontSize,
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    color: "var(--theme-a_headerText)",
    fontWeight: 400,
  },
})

export const H4 = ({ marginLess, children, stylexStyle, style, as }: HeaderProps) => {
  const Tag = as || "h4"
  return (
    <Tag {...stylex.props(h4Styles.base, basicStyles.headingFont, marginLess && basicStyles.marginLess, stylexStyle, style as any)}>
      {children}
    </Tag>
  )
}

const h5Styles = stylex.create({
  base: {
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    fontSize: {
      default: fontSizes.h5FontSize,
      [mediaQueries.mobile]: fontSizes.h5FontSizeMobile,
    },
    lineHeight: lineHeights.fontSize,
    marginTop: spacing.small,
    marginBottom: 0,
    color: "var(--theme-a_headerText)",
    fontWeight: 400,
  },
})

export const H5 = ({ marginLess, children, stylexStyle, style, as }: HeaderProps) => {
  const Tag = as || "h5"
  return (
    <Tag {...stylex.props(h5Styles.base, basicStyles.headingFont, marginLess && basicStyles.marginLess, stylexStyle, style as any)}>
      {children}
    </Tag>
  )
}

type UiHeaderProps = StyledProps & {
  inverse?: boolean
  customColorKey?: keyof Theme
}

export const UiH2 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 28
    return {
      display: "flex",
      fontFamily: "Poppins-Bold",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      lineHeight: fontSize,
    }
  },
  { attrs: { role: "heading", "aria-level": 2 } as any },
)

export const UiH3 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 24
    return {
      display: "flex",
      fontFamily: "Poppins-Bold",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      lineHeight: fontSize,
    }
  },
  { attrs: { role: "heading", "aria-level": 3 } as any },
)

export const UiH4 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 18
    return {
      display: "flex",
      fontFamily: "Poppins-Bold",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      lineHeight: fontSize,
    }
  },
  { attrs: { role: "heading", "aria-level": 4 } as any },
)

export const UiH5 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 14
    return {
      display: "flex",
      fontFamily: "Poppins-Bold",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      lineHeight: fontSize,
    }
  },
  { attrs: { role: "heading", "aria-level": 4 } as any },
)

export type BodyTextOldProps = {
  bold?: boolean
  italic?: boolean
  light?: boolean
  underline?: boolean
  size?: keyof typeof sizeMap
  inverse?: boolean
  customColorKey?: keyof Theme
  customBackgroundKey?: keyof Theme
}
type BodyTextOldPropsWithStyledProps = BodyTextOldProps & StyledProps

const sizeMap = {
  xs: 10,
  small: 12,
  medium: 15,
  large: 22,
  xl: 30,
  xxl: 36,
} as const

// classic iOS style switch, animates dot from left to right when on
export function Switch(props: { size: number; value: boolean; onChange: (val: boolean) => void; children: React.ReactNode }) {
  const { size, onChange, value } = props
  const theme = useTheme()

  const toggle = useCallback(() => {
    onChange(!value)
  }, [onChange, value])

  const padding = 2
  const width = size * 2 + padding * 2

  return (
    <TouchableOpacity activeOpacity={0.75} onPress={toggle}>
      <div
        style={{
          gap: BASE_MARGIN / 2,
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: value ? "row-reverse" : "row",
            width,
            minWidth: width,
            height: size + padding * 2,
            minHeight: size + padding * 2,
            borderRadius: 12,
            backgroundColor: `${theme.fg}33`,
            padding,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              left: 2,
              top: 2,
              width: size,
              height: size,
              borderRadius: 100,
              backgroundColor: props.value ? theme.fg : theme.a_bgAlt,
            }}
          >
            <CheckMark width={size / 1.5} fill={"a_bgAlt"} />
          </div>
        </div>
        {props.children}
      </div>
    </TouchableOpacity>
  )
}

export const InlineText = styled.Text(
  ({
    bold,
    italic,
    light,
    theme,
    customColorKey,
    size,
    selectable,
  }: {
    bold?: boolean
    italic?: boolean
    light?: boolean
    size?: keyof typeof sizeMap
    theme: Theme
    customColorKey?: keyof Theme
    selectable?: boolean
  } & BodyTextOldPropsWithStyledProps) => ({
    fontFamily: light ? "Poppins-Light" : bold ? "Poppins-Bold" : "Poppins-Regular",
    fontStyle: italic ? "italic" : undefined,

    pointerEvents: selectable ? undefined : "none",
    userSelect: selectable ? undefined : "none",
    fontSize: sizeMap[size || "medium"],
    color: customColorKey ? theme[customColorKey] : theme.fg,
  }),
)

/**
 * Note: There are new components `Paragraph` and `BodyText` that use stylex and will eventually
 * replace this one. Use those for new components.
 */
export const BodyTextOld = styled.Text(
  ({ theme, italic, bold, underline, size, light, inverse, customColorKey }: BodyTextOldPropsWithStyledProps) => ({
    fontFamily: light ? "Poppins-Light" : bold ? "Poppins-Bold" : "Poppins-Regular",
    fontStyle: italic ? "italic" : undefined,
    fontSize: sizeMap[size || "medium"],
    color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
    lineHeight: sizeMap[size || "medium"] * 1.3333,
    textDecorationLine: underline ? "underline" : undefined,
  }),
)

type bodyFontSize = keyof typeof fontSizes
export type BodyTextProps = {
  size?: bodyFontSize
  italic?: true
  bold?: true
  semiBold?: true
  underline?: true
  light?: true
  inverse?: true
  customColorKey?: CustomColorKey
} & StylableContainerProps

const bodyTextStyles = stylex.create({
  base: {
    fontFamily: fontFamilies.body,
    fontSize: fontSizes.medium,
    fontWeight: 400,
    color: "var(--theme-fg)",
    // Set whitespace to maintain behavior from previous BodyTextOld styled.Text. If we want to change this,
    // we'll want to make sure things still look good, especially the markdown renderer where we're likely to use newlines.
    whiteSpace: "pre-wrap",
  },
  size: (size: bodyFontSize) => ({
    fontSize: fontSizes[size],
  }),
  changeColor: (colorKey?: CustomColorKey) => ({
    // Use specified color or invert if no color is provided.
    color: colorKey ? `var(--theme-${colorKey})` : "var(--theme-a_bg)",
  }),
})

export const BodyText = ({
  italic,
  bold,
  semiBold,
  underline,
  size,
  light,
  inverse,
  customColorKey,
  children,
  stylexStyle,
  style,
}: BodyTextProps) => {
  return (
    <span
      {...stylex.props(
        bodyTextStyles.base,
        bold && basicStyles.bodyBold,
        semiBold && basicStyles.bodySemiBold,
        light && basicStyles.bodyLight,
        italic && basicStyles.bodyItalic(bold, semiBold, light),
        underline && basicStyles.underline,
        size && bodyTextStyles.size(size),
        (!!customColorKey || inverse) && bodyTextStyles.changeColor(customColorKey),
        stylexStyle,
        style as any,
      )}
    >
      {children}
    </span>
  )
}

type ParagraphProps = {
  size?: bodyFontSize
  marginLess?: true
} & StylableContainerProps

const paragraphStyles = stylex.create({
  base: {
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    lineHeight: lineHeights.larger80,
  },
})

export const Paragraph = ({ size, marginLess, children, style, stylexStyle }: ParagraphProps) => {
  return (
    <p
      {...stylex.props(
        bodyTextStyles.base,
        size && bodyTextStyles.size(size),
        paragraphStyles.base,
        marginLess && basicStyles.marginLess,
        style as any,
        stylexStyle,
      )}
    >
      {children}
    </p>
  )
}

const userTagTextStyles = stylex.create({
  base: {
    backgroundColor: "var(--theme-key)",
    lineHeight: lineHeights.fontSize,
    padding: 4,
    paddingBottom: 2,
  },
})
export const UserTagText = (props: BodyTextProps) => {
  return <BodyText customColorKey="fg" semiBold {...props} stylexStyle={[props.stylexStyle, userTagTextStyles.base]} />
}

export const UserTagTextInline = styled(UserTagText, ({ theme }) => ({
  backgroundColor: theme.a_inlineTag,
  color: theme.type === "light" ? theme.fg : theme.keyFG,
}))

export const HiddenText = styled.Text(({ theme, bold, size, light }: BodyTextOldPropsWithStyledProps) => ({
  fontFamily: light ? "Poppins-Light" : bold ? "Poppins-Bold" : "Poppins-Regular",
  fontSize: sizeMap[size || "medium"],
  color: theme.fg,
  borderRadius: BASE_RADIUS,
  padding: 4,
}))

export const BodyTextAnchor = styled(
  BodyTextOld,
  ({ theme }) =>
    ({
      color: theme.a_anchor,
      textDecorationLine: "underline",
      userSelect: "none",
    }) as const,
)

export const SubtitleText = styled.Text(({ theme }) => ({
  fontFamily: "Poppins-Light",
  fontWeight: "300",
  fontSize: 15,
  color: theme.fg,
  lineHeight: 22,
}))

const secondaryTextStyles = stylex.create({
  base: {
    display: "flex",
    fontFamily: fontFamilies.body,
    fontWeight: 400,
    fontSize: fontSizes.secondaryFontSize,
    color: "var(--theme-fg)",
    lineHeight: lineHeights.default,
    marginBottom: spacing.small,
  },
})
export const SecondaryText = ({ children, stylexStyle, style }: StylableContainerProps) => (
  <div {...stylex.props(bodyTextStyles.base, secondaryTextStyles.base, stylexStyle, style as any)}>{children}</div>
)

export const AdminTextInput = styled.TextInput(({ theme }) => ({
  color: theme.fg,
  backgroundColor: `${theme.fg}22`,
  borderColor: theme.key,
  borderWidth: 2,
  padding: BASE_PADDING,
  height: 30,
}))

export const Screen = (props: { style?: CSSProperties; children?: any }) => {
  const theme = useTheme()
  return (
    <div
      style={{
        backgroundColor: theme.a_bg,
        minHeight: "100%",
        width: "100%",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

export const AppScrollView = styled.ScrollView(({ theme }) => ({
  backgroundColor: theme.a_bg,
}))

export const MainContainer = (props: { children?: any; style?: ViewStyle }) => {
  const isMobile = useIsMobile()
  const multiplier = isMobile ? 1.5 : 2
  const { vh } = useViewport()

  return (
    <View
      style={{
        flexGrow: 1,
        flexShrink: 0,
        maxWidth: 1440,
        paddingLeft: BASE_PADDING * multiplier,
        paddingRight: BASE_PADDING * multiplier,
        paddingBottom: BASE_PADDING * multiplier,
        paddingTop: BASE_PADDING * (multiplier + 0.5),
        width: "100%",
        margin: "auto",
        minHeight: vh(80),
        marginHorizontal: "auto",
        ...props.style,
      }}
    >
      {props.children}
    </View>
  )
}

const boxStyles = stylex.create({
  base: { display: "flex", flexDirection: "column", backgroundColor: "var(--theme-a_bg)", padding: spacing.large },
})
export const Box = ({ children, stylexStyle, style }: StylableContainerProps) => {
  return (
    <div className="box" {...stylex.props(boxStyles.base, stylexStyle, style as any)}>
      {children}
    </div>
  )
}

export const PauseIcon = ({ size = 15, paused, colorKey = "fg" }: { size?: number; paused: boolean; colorKey?: keyof Theme }) => {
  const theme = useTheme()

  return (
    <View style={{ flexDirection: "row", justifyContent: "center", height: size, alignItems: "center", width: size }}>
      {paused ? (
        <View
          style={{
            position: "absolute",
            width: 0,
            left: "25%",
            height: 0,
            borderTopWidth: size * 0.4666,
            borderRightWidth: 0,
            borderBottomWidth: size * 0.4666,
            borderLeftWidth: size * 0.6666,
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: "transparent",
            borderLeftColor: theme[colorKey],
          }}
        />
      ) : (
        <>
          <View style={{ width: "20%", height: "80%", marginRight: "20%", backgroundColor: theme[colorKey] }} />
          <View style={{ width: "20%", height: "80%", backgroundColor: theme[colorKey] }} />
        </>
      )}
    </View>
  )
}

const chevronIconStyles = stylex.create({
  wrapper: (size: number) => ({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: size,
    height: size,
  }),
  line: (color: string) => ({
    position: "absolute",
    borderRadius: radius.small,
    height: "66.66%",
    width: 2,
    backgroundColor: color,
  }),
  downLeft: {
    left: "25%",
    transform: "rotate(-45deg)",
  },
  downRight: {
    right: "25%",
    transform: "rotate(45deg)",
  },
  rightTop: {
    top: 0,
    transform: "rotate(-45deg)",
  },
  rightBottom: {
    top: "40%",
    transform: "rotate(45deg)",
  },
})
export const ChevronIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const color = colorKey ? `var(--theme-${colorKey})` : "var(--theme-fg)"
  if (!size) size = 24

  return (
    <div {...stylex.props(chevronIconStyles.wrapper(size))}>
      <div {...stylex.props(chevronIconStyles.line(color), chevronIconStyles.downLeft)} />
      <div {...stylex.props(chevronIconStyles.line(color), chevronIconStyles.downRight)} />
    </div>
  )
}

export const ChevronRightIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const color = colorKey ? `var(--theme-${colorKey})` : "var(--theme-fg)"
  if (!size) size = 24

  return (
    <div {...stylex.props(chevronIconStyles.wrapper(size))}>
      <div {...stylex.props(chevronIconStyles.line(color), chevronIconStyles.rightTop)} />
      <div {...stylex.props(chevronIconStyles.line(color), chevronIconStyles.rightBottom)} />
    </div>
  )
}

export const CloseIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()

  const color = colorKey ? theme[colorKey] : theme.fg

  if (!size) size = 18

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          borderRadius: 3,
          height: size * 1.3,
          width: 2,
          backgroundColor: color,
          transform: [{ rotate: "45deg" }],
        }}
      />
      <View
        style={{
          position: "absolute",
          borderRadius: 3,
          height: size * 1.3,
          width: 2,
          backgroundColor: color,
          transform: [{ rotate: "-45deg" }],
        }}
      />
    </View>
  )
}

export const PlusIcon = ({ size, colorKey, style }: { size?: number; colorKey?: keyof Theme; style?: ViewStyle }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        ...style,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: 3,
          width: size,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          position: "absolute",
          height: size,
          width: 3,
          backgroundColor: color,
        }}
      />
    </View>
  )
}

export const MinusIcon = ({ size, colorKey, style }: { size?: number; colorKey?: keyof Theme; style?: ViewStyle }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        ...style,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: 3,
          width: size,
          backgroundColor: color,
        }}
      />
    </View>
  )
}
export const RightArrowIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: size * 0.25,
          width: size * 0.75,
          right: size * 0.25,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          position: "absolute",
          width: 0,
          right: 0,
          height: 0,
          borderTopWidth: size * 0.5,
          borderRightWidth: 0,
          borderBottomWidth: size * 0.5,
          borderLeftWidth: size * 0.5,
          borderTopColor: "transparent",
          borderRightColor: "transparent",
          borderBottomColor: "transparent",
          borderLeftColor: color,
        }}
      />
    </View>
  )
}

export const LeftArrowIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: size * 0.25,
          width: size * 0.75,
          left: size * 0.25,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          position: "absolute",
          width: 0,
          left: 0,
          height: 0,
          borderTopWidth: size * 0.5,
          borderRightWidth: size * 0.5,
          borderBottomWidth: size * 0.5,
          borderLeftWidth: 0,
          borderTopColor: "transparent",
          borderRightColor: color,
          borderBottomColor: "transparent",
          borderLeftColor: "transparent",
        }}
      />
    </View>
  )
}

// Hamburger drawn with views
export const Hamburger = ({
  open,
  gapSize = 6,
  size = 26,
  colorKey,
  pauseIcon,
}: {
  gapSize?: number
  size?: number
  colorKey?: keyof Theme
  open: boolean
  pauseIcon?: boolean
}) => {
  const theme = useTheme()

  const padding = 2
  const width = size + padding * 2
  const barHeight = 2
  const gap = gapSize
  const totalHeight = (barHeight + gap) * 3 + gap

  if (pauseIcon) {
    return <PauseIcon size={size} paused={open} colorKey={colorKey || "alwaysLight"} />
  }

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: padding,
        paddingTop: open ? 0 : gap,
        width,
        height: totalHeight,
      }}
    >
      {open ? (
        <CloseIcon colorKey={colorKey || "alwaysLight"} size={size / 1.25} />
      ) : (
        [0, 1, 2].map((i) => (
          <View
            key={i}
            style={{
              borderRadius: 100,
              width: "100%",
              height: barHeight,
              backgroundColor: theme[colorKey || "alwaysLight"],
              marginBottom: gap,
            }}
          />
        ))
      )}
    </View>
  )
}

interface AvatarProps extends ImageProps {
  size?: number
  style?: any
  unround?: boolean
  borderColor?: string
}

export const Avatar = (props: AvatarProps) => {
  const size = props.size || 40
  const borderSize = size >= 60 ? 3 : size >= 24 ? 2 : 1
  const sizeDiff = borderSize * 4
  const borderRadius = props.unround ? 0 : 9999

  return (
    <AvatarOuter
      borderColor={props.borderColor}
      style={{ borderRadius, position: "relative", width: size, height: size, borderWidth: borderSize, ...props.style }}
    >
      <AvatarInner
        {...props}
        defaultSource={props.source as any}
        style={{ borderRadius, width: size - sizeDiff, height: size - sizeDiff }}
      />
    </AvatarOuter>
  )
}

const AvatarOuter = styled.View(({ theme, borderColor }: StyledProps & { theme: Theme; borderColor?: string | undefined }) => ({
  borderColor: borderColor || theme.player,
  alignItems: "center",
  justifyContent: "center",
}))

const AvatarInner = styled.Image(({ theme }) => ({
  backgroundColor: theme.a_bg,
}))

interface StyledTextInputProps extends StyledProps {
  disabled?: boolean
}
export const TextInput = styled.TextInput(({ theme, disabled }: StyledTextInputProps) => ({
  fontFamily: "Poppins-Light",
  fontWeight: "300",
  fontSize: 22,
  marginTop: BASE_MARGIN * 2,
  color: theme.fg,
  backgroundColor: `${theme.fg}22`,
  borderRadius: 0,
  opacity: disabled ? 0.5 : 1,
  padding: BASE_PADDING * 0.5,
  pointerEvents: disabled ? "none" : undefined,
}))

const DevBG = styled.View(() => ({
  borderColor: "red",
  backgroundColor: "#eeaaaa",
  borderWidth: 2,
  borderRadius: BASE_RADIUS,
  padding: BASE_PADDING,
  marginBottom: BASE_MARGIN * 2,
}))

const showDebugUI = () => {
  const ctx = useContext(AppContext)
  const user = useGetUser()
  return userIsAdmin(user) && ctx.showDebugUI
}

export const DevSection = (props: { children: any[] | any }) => {
  if (!showDebugUI()) return null
  return (
    <DevBG>
      <SubtitleText style={{ backgroundColor: "#eeaaaa", color: "black", marginBottom: 4 }}>This is only shown to devs</SubtitleText>
      {props.children}
    </DevBG>
  )
}

// Use a custom replacer to avoid crashing on objects that can't be serialized to JSON
// such as React elements
const devCodeReplacer = (key: any, value: any) => {
  if (typeof value === "object" && React.isValidElement(value)) {
    return "[ReactElement]"
  }
  return value
}

export const DevCode = (props: { title: string; obj: any }) => {
  // We early return here despite the fact DevSection also checks for showDebugUI
  // because the children will execute even when DevSection is null
  if (!showDebugUI()) return null
  return (
    <DevSection>
      <BodyTextOld style={{ backgroundColor: "#eeaaaa", color: "black", marginBottom: 4 }}>{props.title}</BodyTextOld>
      <Code style={{ backgroundColor: "#eeaaaa", color: "black", marginBottom: 4 }}>{JSON.stringify(props.obj, devCodeReplacer, 2)}</Code>
    </DevSection>
  )
}

export const Code = styled.Text(() => ({
  fontFamily: "monospace",
  whiteSpace: "pre-wrap",
}))

export const ANIM_DURATION = 125
function usePressAnimation(
  onPressIn?: ((e: React.PointerEvent) => void) | null,
  onPressOut?: ((e: React.PointerEvent) => void) | null,
  bounceOnMount?: boolean,
  bounceDelay?: number,
) {
  const [styles, setStyles] = useState({ opacity: 1, scale: 1 })

  const mounted = useRef(false)
  useEffect(() => {
    if (!bounceOnMount || mounted.current) return
    const run = async () => {
      await waitFor(bounceDelay || ANIM_DURATION)
      setStyles((s) => ({ ...s, scale: 0.97 }))
      await waitFor(ANIM_DURATION)
      setStyles((s) => ({ ...s, scale: 1 }))
    }

    run()
    mounted.current = true
  }, [bounceDelay, bounceOnMount])

  const startTime = useRef<number | null>(null)
  const pressIn = useCallback(
    (e: React.PointerEvent) => {
      if (onPressIn) onPressIn(e)

      const run = async () => {
        setStyles({ opacity: 0.8, scale: 0.97 })
        // Minimum duration for the animation to run
        startTime.current = Date.now()
        await waitFor(ANIM_DURATION)
        startTime.current = null
      }

      run()
    },
    [onPressIn],
  )

  const pressOut = useCallback(
    (e: React.PointerEvent) => {
      if (onPressOut) onPressOut(e)
      const thisStartTime = startTime.current
      const run = async () => {
        await waitFor(startTime.current ? Math.max(0, ANIM_DURATION - (Date.now() - startTime.current)) : 0)
        // another press started, don't animate back
        if (startTime.current !== null && thisStartTime !== startTime.current) return
        setStyles({ opacity: 1, scale: 1 })
      }
      run()
    },
    [onPressOut],
  )

  return { opacity: styles.opacity, scale: styles.scale, pressIn, pressOut }
}

/** A thin button with a hitslop to hit 44px */
export type ThinButtonProps = {
  children?: React.ReactNode
  onPress?: (e: React.PointerEvent) => void
  onPressIn?: (e: React.PointerEvent) => void
  onPressOut?: (e: React.PointerEvent) => void
  title: string | React.ReactNode
  bgKey?: keyof Theme
  bgColor?: string
  hoverBgKey?: CustomColorKey
  iconAlign?: "left" | "right"
  colorKey?: keyof Theme
  color?: string
  iconComponent?: React.ReactNode
  titleStyle?: TextStyle
  style?: React.CSSProperties
  stylexStyle?: StyleXStyles
  block?: boolean
  type?: "primary" | "secondary"
  bounceOnMount?: boolean
  bounceDelay?: number
  disabled?: boolean
}

export const ThinButton = (props: ThinButtonProps) => {
  const type = props.type || "primary"
  const theme = useTheme()
  const hasEvent = Boolean(props.onPress || props.onPressIn || props.onPressOut)

  let backgroundColor = props.bgColor || (props.bgKey ? theme[props.bgKey] : theme.fg)
  if (type === "secondary") {
    backgroundColor = "transparent"
  }

  const omitted = useMemo(
    () => [
      "block",
      "onPressOut",
      "onPressIn",
      "type",
      "iconAlign",
      "colorKey",
      "bgKey",
      "bgColor",
      "color",
      "bounceOnMount",
      "bounceDelay",
      "iconComponent",
      "titleStyle",
      "hitSlop",
    ],
    [],
  )

  const textColor = props.color || (props.colorKey ? theme[props.colorKey] : theme.a_bg)

  const noEvents = props.disabled || !hasEvent
  return (
    <AnimatedPressableBase
      {...omit(props, omitted)}
      onPress={props.onPress}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      disabled={props.disabled}
      hitSlop={{ top: 10, bottom: 10 }}
      bounceOnMount={props.bounceOnMount}
      bounceDelay={props.bounceDelay}
      stylexStyle={[
        ThinButtonStyles.buttonStyle(backgroundColor, props.hoverBgKey, textColor),
        props.block && ThinButtonStyles.block,
        props.iconAlign === "right" && ThinButtonStyles.alignRight,
        noEvents && ThinButtonStyles.noEvents,
        type === "secondary" && ThinButtonStyles.secondaryButton(textColor),
        props.stylexStyle,
      ]}
      style={props.style}
    >
      {!!props.iconComponent && props.iconComponent}
      {typeof props.title === "string" ? <div {...stylex.props(props.titleStyle as any)}>{props.title}</div> : props.title}
    </AnimatedPressableBase>
  )
}
const ThinButtonStyles = stylex.create({
  buttonStyle: (backgroundColor: string, hoverColor: CustomColorKey | undefined, textColor: string) => ({
    backgroundColor: backgroundColor,
    color: textColor,
    borderRadius: BASE_RADIUS,
    minHeight: 28,
    paddingLeft: 10,
    gap: 6,
    paddingRight: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    fontFamily: fontFamilies.bodySemiBold,
    alignItems: "center",
    fontSize: fontSizes.small,
    ":hover": {
      backgroundColor: hoverColor ? `var(--theme-${hoverColor})` : undefined,
    },
  }),
  alignRight: {
    flexDirection: "row-reverse",
  },
  noEvents: {
    pointerEvents: "none",
  },
  block: {
    textAlign: "center",
    width: "100%",
    justifyContent: "center",
  },
  secondaryButton: (borderColor: string) => ({
    borderWidth: 1,
    borderColor: borderColor,
    borderStyle: "solid",
  }),
})

/** Chunkier buttons */
export const PuzmoButton: React.FC<ThinButtonProps> = (props) => {
  return (
    <ThinButton
      {...props}
      style={{
        minHeight: 34,
        ...(props.style || undefined),
      }}
      titleStyle={{
        fontSize: 14,
        ...props.titleStyle,
      }}
    />
  )
}

export type AdminButtonProps = PressableProps & {
  title: string | React.ReactNode
  disabled?: boolean
  style?: ViewStyle
}

export const AdminButton = (props: AdminButtonProps) => {
  const theme = useTheme()
  return (
    <Pressable
      {...props}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      hitSlop={{ top: 10, bottom: 10 }}
      style={[
        {
          borderColor: theme.fg,
          borderWidth: 1,
          borderRadius: BASE_RADIUS,
          height: 28,
          paddingLeft: 8,
          paddingRight: 8,
          justifyContent: "center",
          ...(props.style ? props.style : {}),
        },
        { opacity: props.disabled ? 0.3 : 1 },
      ]}
    >
      {typeof props.title === "string" ? (
        <Text
          style={{
            color: theme.fg,
            fontSize: 10,
            fontFamily: "ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace",
          }}
        >
          {props.title}
        </Text>
      ) : (
        props.title
      )}
    </Pressable>
  )
}

export const AnimatedPressableBase: React.FC<{
  children: React.ReactNode
  disabled?: boolean
  disabledOpacity?: number
  style?: React.CSSProperties
  // This is resolved to onClick, so it's event type is not PointerEvent
  onPress?: (e: React.MouseEvent) => void
  // These get converted to the web pointer events
  // this is for compatibility with the react native pressable
  // at some point we might want to convert everything over.
  onPressIn?: (e: React.PointerEvent) => void
  onPressOut?: (e: React.PointerEvent) => void
  // buffer around the button to make it easier to hit
  hitSlop?: { top?: number; bottom?: number; left?: number; right?: number }
  bounceOnMount?: boolean
  bounceDelay?: number
  stylexStyle?: StyleXStyles
}> = (props) => {
  const { opacity, scale, pressIn, pressOut } = usePressAnimation(props.onPressIn, props.onPressOut, props.bounceOnMount, props.bounceDelay)
  const disabledOpacity = props.disabledOpacity || 0.5

  return (
    <div
      onPointerDown={pressIn}
      onPointerUp={pressOut}
      onPointerCancel={pressOut}
      onClick={props.onPress}
      tabIndex={props.disabled ? -1 : 0}
      role="button"
      {...stylex.props(
        pressableBaseStyles.baseStyle(props.disabled ? disabledOpacity : opacity, scale),
        props.disabled && pressableBaseStyles.disabled,
        props.style as any,
        props.stylexStyle,
      )}
    >
      {props.children}
      {props.hitSlop && (
        <div
          style={{
            position: "absolute",
            top: -(props.hitSlop?.top || 0),
            left: -(props.hitSlop?.left || 0),
            right: -(props.hitSlop?.right || 0),
            bottom: -(props.hitSlop?.bottom || 0),
          }}
        />
      )}
    </div>
  )
}

const pressableBaseStyles = stylex.create({
  baseStyle: (opacity: number, scale: number) => ({
    display: "flex",
    alignItems: "stretch",
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    position: "relative",
    userSelect: "none",
    flexBasis: "auto",
    flexDirection: "column",
    flexShrink: 0,
    cursor: "pointer",
    opacity: opacity,
    transform: `scale(${scale})`,
    transition: `transform ${animDurations.short}, opacity ${animDurations.short}`,
  }),
  disabled: {
    pointerEvents: "none",
  },
})

/** A thin square button with a hitslop to hit for 44px */
export const SquareButton: React.FC<{
  bgKey?: keyof Theme
  style?: React.CSSProperties
  children?: React.ReactNode
  onPress?: (e: React.MouseEvent) => void
  onPressIn?: (e: React.PointerEvent) => void
  onPressOut?: (e: React.PointerEvent) => void
  disabled?: boolean
}> = (props) => {
  const theme = useTheme()

  return (
    <AnimatedPressableBase
      onPress={props.onPress}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      disabled={props.disabled}
      hitSlop={{ top: 10, bottom: 10 }}
      style={{
        backgroundColor: props.bgKey ? theme[props.bgKey] : "transparent",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: BASE_RADIUS,
        height: 26,
        width: 26,
        ...(props.style || {}),
      }}
    >
      {props.children}
    </AnimatedPressableBase>
  )
}

interface RowProps extends StyledProps {
  bottomBorder?: boolean
  centerV?: true
  centerH?: true
  marginT?: true
  wrap?: true
}

export const Row = styled.View(({ bottomBorder, theme, centerV, centerH, marginT, wrap }: RowProps) => {
  const style: ViewStyle = { flexDirection: "row" }

  if (bottomBorder) {
    style.borderBottomColor = `${theme.a_headerText}66`
    style.borderBottomWidth = 1
  }
  if (centerV) style.justifyContent = "center"
  if (centerH) style.alignItems = "center"
  if (marginT) style.marginTop = BASE_MARGIN * 2
  if (wrap) style.flexWrap = "wrap"
  return style
})

export const Col = styled.View(({ bottomBorder, theme, centerV, centerH, marginT }: RowProps) => {
  const style: ViewStyle = {}

  if (bottomBorder) {
    style.borderBottomColor = theme.fg
    style.borderBottomWidth = 1
  }
  if (centerV) style.alignItems = "center"
  if (centerH) style.justifyContent = "center"
  if (marginT) style.marginTop = BASE_MARGIN * 2
  return style
})

/** Basically useful for whenever you're sending someone outside of the RN app  */
export const ExternalLink = (props: {
  inline?: boolean
  to: string
  children: any
  aProps?: React.HTMLProps<HTMLAnchorElement>
  target?: HTMLAttributeAnchorTarget
  style?: React.CSSProperties
}) => (
  <AnimatedPressableBase style={{ ...props.style, display: props.inline ? "inline-flex" : "flex" }}>
    <a href={props.to} target={props.target || "_blank"} rel="noopener noreferrer" {...props.aProps}>
      {props.children}
    </a>
  </AnimatedPressableBase>
)

export const UserLink = (props: { user: { username: string; usernameID: string; name?: string }; children: any }) => {
  const theme = useTheme()
  const loggedInUser = useGetUser()

  const user = props.user
  if (!user) return null

  const isUser =
    loggedInUser.type === "user" &&
    loggedInUser.currentUser.username === props.user.username &&
    loggedInUser.currentUser.usernameID === props.user.usernameID

  const color = isUser ? theme.player : theme.keyStrong

  return (
    <InternalLink screen={"UserProfile"} routeOpts={{ username: user.username, usernameID: user.usernameID }}>
      <BodyText style={{ color }}>{props.children}</BodyText>
    </InternalLink>
  )
}

export const InternalLink = <T extends keyof typeof namedRoutes>(props: {
  screen: T
  // These are the route params for routes like /play/:gameSlug,
  // seeing errors in these two types, you likelly need to go edit
  // the two types which derive from the namedRoutes const
  routeOpts?: RouteParameters[T]
  params?: RouteQueryParams[T]
  children: React.ReactNode
  onPress?: () => void
  disabled?: boolean
  disabledOpacity?: number
  style?: React.CSSProperties
  inline?: boolean
  wrapperStyle?: React.CSSProperties
  target?: "_blank"
  linkProps?: TextProps
}) => {
  const { launchInfo, appRuntime } = useAppContext()
  const href = props.disabled
    ? ""
    : getPathForNavigationRoute(launchInfo.partnerSlug, appRuntime, props.screen, props.routeOpts, props.params)

  return (
    <AnimatedPressableBase
      onPress={props.onPress}
      disabled={props.disabled}
      disabledOpacity={props.disabledOpacity}
      style={{ ...props.wrapperStyle, display: props.inline ? "inline-flex" : "flex", pointerEvents: props.disabled ? "none" : "auto" }}
    >
      <Link href={href} style={{ color: "inherit", font: "inherit", ...props.style }} target={props.target}>
        {props.children}
      </Link>
    </AnimatedPressableBase>
  )
}

export const Border = styled.View(({ theme }) => ({
  borderBottomColor: theme.a_headerText,
  height: 0,
  borderBottomWidth: 1,
}))

export const BorderCenter = ({ style }: { style?: ViewStyle }) => (
  <View style={[{ justifyContent: "center", flexGrow: 1 }, style]}>
    <Border />
  </View>
)

export const Select = ({
  value,
  onChange,
  values,
  displays,
  disabled,
}: {
  value: string
  onChange: (value: string) => void
  values: string[]
  displays: string[]
  disabled?: boolean
}) => {
  const theme = useTheme()
  return (
    <div style={{ position: "relative" }}>
      <Picker
        style={{
          width: "100%",
          height: "100%",
          // @ts-ignore
          visibility: disabled ? "hidden" : "visible",
          position: "absolute",
          borderRadius: BASE_RADIUS,
          borderWidth: 0,
          backgroundColor: "transparent",
          WebkitAppearance: "none",
          cursor: "pointer",
        }}
        selectedValue={value}
        onValueChange={disabled ? () => null : (itemValue) => onChange(itemValue)}
      >
        {values.map((o, i) => (
          <Picker.Item key={i} value={o} label={displays[i]} />
        ))}
      </Picker>
      <div
        style={{
          display: "flex",
          pointerEvents: "none",
          position: "relative",
          alignItems: "center",
          height: 28,
          paddingLeft: 10,
          paddingRight: 40,
          paddingTop: 2,
          borderRadius: BASE_RADIUS,
          opacity: disabled ? 0.66 : 1,
          backgroundColor: "var(--theme-key)",
        }}
      >
        <BodyText customColorKey="keyFG" semiBold stylexStyle={[basicStyles.singleLineOverflowEllipsis, basicStyles.noSelect]}>
          {displays[values.indexOf(value)]}
        </BodyText>
      </div>
      <div style={{ pointerEvents: "none", position: "absolute", top: 5, right: 10 }}>
        <ChevronIcon size={18} colorKey={"keyFG"} />
      </div>
    </div>
  )
}

export const CheckboxBar = ({
  label,
  stateLabels = ["on", "off"],
  bgColors,
  textColor,
  checked,
  bold,
  onChange,
  disabled,
}: {
  label: string
  checked: boolean
  bgColors?: [string, string]
  textColor?: string
  bold?: boolean
  stateLabels?: [string, string]
  onChange: (checked: boolean) => void
  disabled?: boolean
}) => {
  const theme = useTheme()
  const bg = bgColors || [theme.key, theme.keyStrong]
  return (
    <AnimatedPressableBase style={{ width: "100%" }} onPress={() => onChange(!checked)} disabled={disabled}>
      <View
        style={{
          backgroundColor: bg[checked ? 0 : 1],
          borderRadius: 6,
          flexDirection: "row",
          alignItems: "center",
          height: 28,
        }}
      >
        <Text
          selectable={false}
          style={{
            paddingTop: 2,
            color: textColor || theme.keyFG,
            fontFamily: bold ? "Poppins-Bold" : "Poppins-Semibold",
            fontSize: 15,
            lineHeight: 18,
            textAlignVertical: "center",
            flex: 1,
            paddingHorizontal: 10,
            // @ts-ignore
            userSelect: "none",
          }}
        >
          {label}
        </Text>
        <View
          style={{
            flexShrink: 0,
            flexGrow: 0,
            height: 28,
            paddingTop: 2,
            width: 50,
            justifyContent: "center",
            alignItems: "center",
            borderLeftColor: textColor || theme.keyFG,
            borderLeftWidth: 2,
            borderStyle: "dashed",
          }}
        >
          <Text
            style={{
              color: textColor || theme.keyFG,
              fontSize: 14,
              lineHeight: 18,
              textAlignVertical: "center",
              fontFamily: "Poppins-Semibold",
              textTransform: "uppercase",
              // @ts-ignore
              userSelect: "none",
            }}
          >
            {stateLabels[checked ? 0 : 1]}
          </Text>
        </View>
      </View>
    </AnimatedPressableBase>
  )
}

interface ShadowProps extends StyledProps {
  subtle?: true
}

export const ShadowView = styled.View<ShadowProps>(({ theme, subtle }) => ({
  shadowColor: theme.alwaysDark,
  shadowOffset: {
    width: 0,
    height: subtle ? 3 : 6,
  },
  shadowOpacity: subtle ? 0.1 : 0.33,
  shadowRadius: 18,
  elevation: 5,
}))

// @ts-ignore – vh units are not supported in react-native but we are web only
export const ModalFrame = styled.View(({ theme }) => {
  const { vh } = useViewport()
  return {
    backgroundColor: theme.alwaysDark,
    borderRadius: 12,
    shadowColor: blendColors(theme.alwaysDark, "#000000", 0.25),
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: 600,
    maxHeight: vh(90),
    marginHorizontal: 24,
    width: "100%",
  }
})

export const ModalShadowBox = styled.View(({ theme }) => ({
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: `${theme.a_bg}88`,
}))

export interface PaddingProps extends StyledProps {
  p?: number
  px?: number
  py?: number
}

export const Padding = styled.View(({ p, px, py }: PaddingProps) => {
  if (p !== undefined) {
    return { padding: p * BASE_PADDING }
  }

  return {
    paddingLeft: (px || 0) * BASE_PADDING,
    paddingRight: (px || 0) * BASE_PADDING,
    paddingTop: (py || 0) * BASE_PADDING,
    paddingBottom: (py || 0) * BASE_PADDING,
  }
})

export function Gap({
  gap = BASE_PADDING,
  direction = "vertical",
  style,
  flex = direction === "horizontal" ? 1 : undefined,
  flexes,
  divider,
  children,
}: {
  gap?: number
  direction?: "vertical" | "horizontal"
  style?: ViewStyle
  flex?: number
  // flexes is an array of flex values for each child at the index
  flexes?: number[]
  divider?: React.ReactNode
  children?: React.ReactNode
}) {
  const kids = flattenChildren(children)
  const flexDirection = direction === "vertical" ? "column" : "row"
  const styleKey = direction === "vertical" ? "height" : "width"

  if (kids.length === 1) return <View style={[{ width: "100%" }, style]}>{children}</View>

  return (
    <View style={[{ flexDirection }, style]}>
      {Children.map(kids, (child, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 ? divider || <View style={{ [styleKey]: gap }} /> : null}
            <View style={{ flex: flexes?.[index] || flex }}>{child}</View>
          </React.Fragment>
        )
      })}
    </View>
  )
}

export function TopGradientShadow(props: { height: number; color?: string }) {
  const theme = useTheme()
  const color = props.color || theme.a_bg
  return (
    <View
      style={{
        height: "100%",
        top: "-100%",
        position: "absolute",
        left: 0,
        right: 0,
        pointerEvents: "none",
      }}
    >
      <LinearGradient
        colors={[`${color}00`, color, color]}
        locations={[0, 0.8, 1]}
        style={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          left: 0,
          right: 0,
          height: props.height,
        }}
      />
    </View>
  )
}

export const TableWrapper = styled.View(() => ({
  borderRadius: BASE_RADIUS,
  flexDirection: "column",
  overflow: "hidden",
  width: "100%",
}))

interface TableRowProps extends StyledProps {
  even?: boolean
  theme: Theme
}

export const TableRow = styled.View(({ theme, even }: TableRowProps) => ({
  flexDirection: "row",
  flexGrow: 1,
  flexShrink: 1,
  maxWidth: "100%",
  paddingHorizontal: BASE_PADDING * 0.5,
  backgroundColor: even ? theme.a_table : theme.a_tableAlt,
  justifyContent: "space-between",
}))

export const StickyWrapper = (props: { style?: any; children?: any }) => {
  const overridableStyles = { top: 0, zIndex: 100 }
  return <div style={{ ...overridableStyles, ...(props.style ? props.style : {}), position: "sticky" }}>{props.children}</div>
}
