import stylex from "@stylexjs/stylex"

import { fontFamilies } from "./tokens.stylex"

export const basicStyles = stylex.create({
  headingFont: {
    fontFamily: fontFamilies.headingBold,
  },
  marginLess: {
    marginTop: 0,
    marginBottom: 0,
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
  singleLineOverflowEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  noSelect: {
    userSelect: "none",
  },
  // Note: We should avoid using font-weights and font-styles for fonts like Poppins which have
  // individual font-families defined for different weights and styles, otherwise browsers may apply
  // faux bold and italic styles on top of the base font.
  bodyBold: {
    fontFamily: fontFamilies.bodyBold,
  },
  bodySemiBold: {
    fontFamily: fontFamilies.bodySemiBold,
  },
  bodyLight: {
    fontFamily: fontFamilies.bodyLight,
  },
  // This is kind of a gross helper but it handles the combination of font-weight and font-style.
  bodyItalic: (bold?: boolean, semiBold?: boolean, light?: boolean) => ({
    fontFamily: bold
      ? fontFamilies.bodyBoldItalic
      : semiBold
        ? fontFamilies.bodySemiBoldItalic
        : light
          ? fontFamilies.bodyLightItalic
          : fontFamilies.bodyItalic,
  }),
  underline: {
    textDecorationLine: "underline",
  },
})
