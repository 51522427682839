import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTheme } from "styled-rn"

import { BodyText, ExternalLink, MainContainer, Paragraph, Screen, ThinButton } from "@components/DesignSystem"
import { storageKeys } from "@consts/constants"
import { spacing } from "@palette/tokens.stylex"
import { secondsToHms } from "@puzzmo-com/shared/secondsToHms"
import { captureException } from "@sentry/browser"
import stylex from "@stylexjs/stylex"

import { useAppContext } from "../../AppContext"
import race from "../../assets/images/race@2x.png"
import { RelayErrorInfo } from "../lib/RelayProvider"

export const CriticalFailureScreen = (props: { errorMessage: string; error?: Error; info?: RelayErrorInfo; retry: () => void }) => {
  const secsTillRetry = 20
  const retryTimeRef = useRef<number | null>(null)
  if (retryTimeRef.current === null) {
    retryTimeRef.current = new Date().getTime() + secsTillRetry * 1000
  }

  const retryTime = retryTimeRef.current
  const [countdown, setCountDown] = useState(secsTillRetry * 1000)

  const shouldDefaulToOpen: boolean =
    document.location.host.includes("localhost") || document.location.host.includes("staging") || localUserIsAdmin()
  const [showDetails, setShowDetails] = useState<boolean>(shouldDefaulToOpen)

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(retryTime - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [retryTime])

  if (countdown < 0) {
    props.retry()
  }

  const guestimates: any = {
    "Load failed": "Your device couldn't reach Puzzmo's API, perhaps you (or it) are offline?",
  }

  const toggleDetails = useCallback(() => setShowDetails((d) => !d), [setShowDetails])

  const theme = useTheme()
  const { apiClient, appRuntime, launchInfo } = useAppContext()

  useEffect(() => {
    if (!props.info?.errorReported) {
      const stripWeirdDupes = props.errorMessage.replaceAll(", Something went wrong.", "")
      const error = new Error(stripWeirdDupes)
      captureException(error, {})
    }
  }, [props.errorMessage, props.info])

  const isDev = process.env.NODE_ENV === "development"

  return (
    <div>
      <div {...stylex.props(s.centered)}>
        <MainContainer style={{ maxWidth: 600 }}>
          <div {...stylex.props(s.centered)}>
            <img src={race} style={{ width: 400, maxWidth: 560, maxHeight: 600 }} />
          </div>
          <BodyText style={{ marginBottom: 8 }}>Uh-oh, there seems to be an issue with Puzzmo</BodyText>

          <BodyText style={{ marginBottom: 8 }}>
            Sorry! Someone on the team has been alerted. If you want more info, please check in{" "}
            <ExternalLink aProps={{ style: { color: theme.a_anchor } }} to="https://discord.gg/puzzmo" inline>
              our Discord
            </ExternalLink>{" "}
            or the{" "}
            <ExternalLink aProps={{ style: { color: theme.a_anchor } }} to="https://puzzmo-com.github.io/status/" inline>
              Puzzmo Status
            </ExternalLink>{" "}
            page.
          </BodyText>

          <BodyText style={{ marginBottom: 8 }}>Re-trying in {secsTillRetry || "0"} seconds.</BodyText>

          <div {...stylex.props(s.buttons)}>
            <ThinButton
              title={`Try refresh (${secondsToHms(countdown / 1000) || "now"})`}
              onPress={props.retry}
              block
              style={{ flex: 1 }}
            />
            <ThinButton
              title={`${showDetails ? "Hide" : "Show"} details`}
              colorKey="fg"
              onPress={toggleDetails}
              block
              type="secondary"
              style={{ flex: 1 }}
            />
          </div>

          {showDetails && (
            <div {...stylex.props(s.details)}>
              <BodyText bold>{guestimates[props.errorMessage] || props.errorMessage}</BodyText>
              {(props.info?.stack || props.error?.stack) && (
                <BodyText>
                  <pre style={{ maxWidth: "100%", overflow: "auto", fontSize: 12 }}>
                    <code>{props.info?.stack || props.error?.stack}</code>
                  </pre>
                </BodyText>
              )}

              <Paragraph>
                Runtime: <code>{appRuntime}</code>
              </Paragraph>
              {Object.keys(launchInfo.params).length > 0 && (
                <Paragraph>
                  Bootup: <code>{JSON.stringify(launchInfo.params)}</code>
                </Paragraph>
              )}
              {props.info?.sentryTraceID && !isDev && (
                <Paragraph>
                  Admin:{" "}
                  <a href={`https://puzmo.sentry.io/traces/trace/${props.info.sentryTraceID}`}>
                    Sentry Trace ID: {props.info.sentryTraceID}
                  </a>
                </Paragraph>
              )}
              {props.info?.query && (
                <Paragraph>
                  Query: <code>{props.info.query}</code>
                </Paragraph>
              )}
              <Paragraph>
                Gameplay ID: <code>{apiClient.getUserID()}</code>
              </Paragraph>
              <Paragraph>
                API: <code>{apiClient.apiRoot()}</code>
              </Paragraph>
            </div>
          )}
        </MainContainer>
      </div>
    </div>
  )
}

const s = stylex.create({
  centered: { justifyContent: "center", alignItems: "center", display: "flex" },

  buttons: {
    display: "flex",
    gap: spacing.small,
    marginTop: spacing.medium,
  },

  details: {
    marginTop: spacing.medium,
  },
})

const localUserIsAdmin = () => {
  const existingLogin = localStorage.getItem(storageKeys.puzzmoBootstrapData)
  if (!existingLogin) return false

  try {
    const details = JSON.parse(existingLogin)
    const userRoles = details?.user?.roles as string
    return userRoles?.includes("admin")
  } catch (error) {
    return false
  }
}
