import { useContext, useMemo } from "react"

import { AnimatedPressableBase, Avatar, BASE_MARGIN, BodyTextAnchor } from "@components/DesignSystem"
import stylex from "@stylexjs/stylex"

import { useGetUser } from "../CoreContext"
import { useIsMobile } from "../hooks/useIsMobile"
import { spacing } from "../palette/tokens.stylex"
import { useGetNavigationHeaderViewModel } from "./NavigationHeaderViewModel"
import { HeaderContext } from "./headerFaceoff/HeaderContext"

const styles = stylex.create({
  avatarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: spacing.medium,
  },
})

export function PlayerDropdown() {
  const user = useGetUser()
  const navigationHeaderViewModel = useGetNavigationHeaderViewModel()
  const headerContext = useContext(HeaderContext)
  const isMobile = useIsMobile()
  const toggle = () => headerContext.setShowAccountDropdown((v) => !v)

  const avatarDecorationColor =
    navigationHeaderViewModel.navigationBackgroundAppearance === "key" ? navigationHeaderViewModel.navigationForegroundColor : undefined

  const avatarSource = useMemo(() => {
    return { uri: user.currentUser?.avatarURL, width: 30 * 2, height: 30 * 2 }
  }, [user.currentUser?.avatarURL])

  const name = user.currentUser?.name || user.currentUser?.username
  const ariaLabel = `User account dropdown for ${name}`

  return (
    <AnimatedPressableBase
      onPress={toggle}
      style={{ alignContent: "center" }}
      aria-label={ariaLabel}
      aria-expanded={headerContext.showAccountDropdown}
      aria-role="button"
    >
      <div {...stylex.props(styles.avatarContainer)}>
        {!isMobile && (
          <BodyTextAnchor
            style={{
              color: avatarDecorationColor,
              textDecorationLine: "none",
              backgroundColor: "none",
              marginHorizontal: 8,
            }}
          >
            {name}
          </BodyTextAnchor>
        )}
        <Avatar size={30} source={avatarSource} borderColor={avatarDecorationColor} />
      </div>
    </AnimatedPressableBase>
  )
}
