import React from "react"
import { Theme } from "styled-rn"

import { Tooltip } from "@palette/Tooltip"
import { opacities, radius, spacing } from "@palette/tokens.stylex"
import stylex from "@stylexjs/stylex"

import { useGetUser } from "../CoreContext"
import { useAppSettingsEditMutation } from "../mutations/useAppSettingsEditMutation"
import { timeHolder } from "../util/timeHolder"
import { AnimatedPressableBase, BodyText, InlineText } from "./DesignSystem"
import { TimerIcon } from "./icons/TimerIcon"

export const TimerInfo = (props: { size?: "large"; foregroundColorKey?: keyof Theme }) => {
  const user = useGetUser()

  const canHide = user.type !== "anon"
  const [updateAppSettings, loading] = useAppSettingsEditMutation()

  const hide = user.currentUser?.settings.hideTimer
  const [time, setTime] = React.useState(timeHolder.timeDisplay)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(timeHolder.timeDisplay)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  let body = (
    <>
      <BodyText
        style={{ width: props.size ? "auto" : 30 }}
        size={props.size || "small"}
        customColorKey={props.foregroundColorKey}
        key="timer"
      >
        {time[0]}
      </BodyText>
      {time[1] ? (
        <div {...stylex.props(styles.timeContainer)}>
          <BodyText size={props.size || "small"} customColorKey={props.foregroundColorKey}>
            +{time[1]}
          </BodyText>
        </div>
      ) : null}
    </>
  )

  if (hide) {
    body = (
      <div {...stylex.props(styles.hideContainer)}>
        <InlineText customColorKey="fg" size={props.size || "small"}></InlineText>
        <TimerIcon width={18} fill={props.foregroundColorKey || "fg"} />
      </div>
    )
  }

  return (
    <Tooltip disabled={!canHide} text={`${hide ? "Show timer" : "Hide timer"}`}>
      <AnimatedPressableBase
        disabled={loading}
        stylexStyle={[styles.pressableBase, loading && styles.loading]}
        onPress={
          !canHide
            ? () => null
            : () =>
                updateAppSettings({
                  hideTimer: !hide,
                })
        }
      >
        {body}
      </AnimatedPressableBase>
    </Tooltip>
  )
}

const styles = stylex.create({
  timeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing.small,
    borderRadius: radius.small,
    marginLeft: spacing.small,
    backgroundColor: "var(--theme-a_puzmo-a20)",
  },
  hideContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  loading: {
    opacity: opacities.o50,
  },
  pressableBase: {
    alignItems: "center",
    flexDirection: "row",
    borderRadius: radius.small,
    padding: spacing.small,
    ":hover": {
      backgroundColor: "var(--theme-alwaysLight-a10)",
    },
  },
})
