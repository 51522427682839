import { useContext } from "react"
import { useRoute } from "wouter"

import { InternalLink } from "@components/DesignSystem"
import { useTodayLinkRouteOpts } from "@components/TodayLink"
import { BackArrow } from "@components/icons/BackArrow"
import { faceoffMobileHeaderHeight } from "@consts/constants"
import stylex from "@stylexjs/stylex"

import { useGetPartner } from "../../CoreContext"
import { scrollToTop } from "../../lib/scroll"
import { spacing } from "../../palette/tokens.stylex"
import { namedRoutes } from "../LinkingConfiguration"
import { LogoWithAnimation } from "../LogoWithAnimation"
import { useGetNavigationHeaderViewModel } from "../NavigationHeaderViewModel"
import { PlayerDropdown } from "../PlayerDropdown"
import { HeaderContext } from "./HeaderContext"

export function MobileHeaderFaceoff() {
  const viewModel = useGetNavigationHeaderViewModel()
  const headerContext = useContext(HeaderContext)

  return (
    <div {...stylex.props(styles.headerContainer(viewModel.navigationBackgroundColor))}>
      <div {...stylex.props(styles.leftContainer)}>
        <div ref={headerContext.leftHandContainerEl} />
      </div>
      <MobileTitle />
      <div {...stylex.props(styles.rightContainer)}>
        <div ref={headerContext.rightHandContainerEl} />
        <PlayerDropdown />
      </div>
    </div>
  )
}

const MobileTitle = () => {
  const headerContext = useContext(HeaderContext)
  const partner = useGetPartner()
  const bgColorKey = partner?.navBG === "Key" ? "key" : partner?.navBG === "Light" ? "alwaysLight" : "alwaysDark"
  // TODO: Actually handle partner pages and render a different UI for that.
  // TODO: handle changes to title on navigation, e.g. to game page

  return (
    <div ref={headerContext.logoContainerEl}>
      {!headerContext.portalState.logo && (
        <div {...stylex.props(styles.title)} onClick={() => scrollToTop()}>
          <LogoWithAnimation width={115} backgroundColor={`var(--theme-${bgColorKey}`} />
        </div>
      )}
    </div>
  )
}

const styles = stylex.create({
  headerContainer: (color: string) => ({
    position: "sticky",
    top: 0,
    backgroundColor: color,
    zIndex: 2,
    height: faceoffMobileHeaderHeight,
    display: "flex",
    alignItems: "center",
    paddingHorizontal: spacing.medium,
  }),
  leftContainer: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
    justifyItems: "flex-end",
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginTop: spacing.small,
  },
})
