import { useCallback, useState } from "react"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"
import { debounce } from "ts-debounce"

import { BASE_MARGIN, BodyText } from "@components/DesignSystem"
import { Form } from "@components/OldForm"
import { spacing } from "@palette/tokens.stylex"
import { ClubsSearchQuery, ClubsSearchQuery$data } from "@relay/ClubsSearchQuery.graphql"
import stylex from "@stylexjs/stylex"

import { GroupSearchResult } from "./GroupSearchResult"

const searchQuery = graphql`
  query ClubsSearchQuery($query: String!) {
    search {
      groups(query: $query) {
        edges {
          node {
            id
            ...GroupSearchResult
          }
        }
      }
    }
  }
`

export const ClubsSearch = (props: { clubsYouAreIn: { group?: { id: string } | null }[] }) => {
  const [searchResults, setSearchResults] = useState<ClubsSearchQuery$data["search"]["groups"]["edges"]>()
  const [loading, setLoading] = useState(false)
  const env = useRelayEnvironment()

  const getSearchResults = (input: string) => {
    if (input === "") {
      return setSearchResults(undefined)
    }

    setLoading(true)

    fetchQuery<ClubsSearchQuery>(env, searchQuery, { query: input })
      .toPromise()
      .then((r) => {
        if (r?.search.groups.edges) {
          setSearchResults(r.search.groups.edges)
        } else {
          setSearchResults([])
        }
        setLoading(false)
      })
  }

  const debouncedInputUpdate = useCallback(debounce(getSearchResults, 250, { isImmediate: false }), [])

  const clearSearch = () => {
    // TODO: this should clear the query too but we're not currently using a controlled input component.
    setSearchResults(undefined)
  }

  return (
    <div {...stylex.props(styles.resultsForm)}>
      <Form.SearchInput
        wrapperStyle={{ flexGrow: 1, marginRight: BASE_MARGIN / 2, flexShrink: 0 }}
        showClearButton={Boolean(searchResults)}
        onClear={clearSearch}
        placeholder="Search clubs"
        onChangeText={debouncedInputUpdate}
      />
      {searchResults && (
        <div {...stylex.props(styles.resultsContainer)}>
          {searchResults.map((i, idx) =>
            !i ? null : (
              <GroupSearchResult
                inGroup={Boolean(props.clubsYouAreIn.find((g) => g.group?.id === i.node.id))}
                isFirst={idx === 0}
                key={i.node.id}
                group={i.node}
              />
            ),
          )}
          {searchResults && searchResults.length === 0 ? (
            <div>
              <BodyText>No results</BodyText>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

const styles = stylex.create({
  resultsForm: {
    display: "flex",
    flexDirection: "column",
  },
  resultsContainer: {
    marginTop: spacing.large,
    maxHeight: 300,
    overflowY: "auto",
  },
})
