import stylex from "@stylexjs/stylex"

// Spacing tokens, typically used for margins and padding.
export const spacing = stylex.defineVars({
  xsmall: "3px",
  small: "6px",
  medium: "12px",
  ml: "18px",
  large: "24px",
  xlarge: "48px",
})

export const radius = stylex.defineVars({
  small: "5px",
  medium: "10px",
  large: "20px",
  // This is a proxy for fully rounded corners (e.g. the half-circle ends of a pill shaped element)
  round: "9999px",
})

export const fontFamilies = stylex.defineVars({
  headingBold: "Zodiak-bold",
  headingExtraBold: "Zodiak-Extrabold",
  bodyOld: "LeagueSpartan",
  body: "Poppins-Regular",
  bodyItalic: "Poppins-Italic",
  bodySemiBold: "Poppins-SemiBold",
  bodySemiBoldItalic: "Poppins-SemiBoldItalic",
  bodyBold: "Poppins-Bold",
  bodyBoldItalic: "Poppins-BoldItalic",
  bodyLight: "Poppins-Light",
  bodyLightItalic: "Poppins-LightItalic",
})

export const animDurations = stylex.defineVars({
  short: "0.125s",
  medium: "0.25s",
})

export const opacities = stylex.defineVars({
  o50: 0.5,
  o01: 0.1,
})

const baseFontSizes = {
  h2: 45,
  h3: 36,
  h4: 24,
  h5: 15,
  secondaryText: 12,
}

export const fontSizes = stylex.defineVars({
  h2FontSize: `${baseFontSizes.h2}px`,
  h2FontSizeMobile: `${(baseFontSizes.h2 * 2) / 3}px`,
  h3FontSize: `${baseFontSizes.h3}px`,
  h3FontSizeMobile: `${(baseFontSizes.h3 * 7) / 9}px`,
  h4FontSize: `${baseFontSizes.h4}px`,
  h4FontSizeMobile: `${(baseFontSizes.h4 * 3) / 4}px`,
  h5FontSize: `${baseFontSizes.h5}px`,
  h5FontSizeMobile: `${(baseFontSizes.h5 * 9) / 10}px`,
  secondaryFontSize: `${baseFontSizes.secondaryText}px`,
  xsmall: "10px",
  small: "12px",
  medium: "15px",
  large: "22px",
})

export const lineHeights = stylex.defineVars({
  // This is roughly the normal line height for most browsers.
  default: 1.2,
  fontSize: 1,
  larger33: 1.3333,
  larger80: 1.8,
  smaller20: 0.8,
})
