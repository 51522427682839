import { graphql, useFragment } from "react-relay"

import { BodyText, InternalLink } from "@components/DesignSystem"
import { GameIcon } from "@components/Thumbnails/games/GameIcon"
import { GroupAvatar } from "@components/groups/GroupAvatar"
import { CrownIcon } from "@components/icons/CrownIcon"
import { UserDisplay } from "@components/profiles/UserDisplay"
import { INCLUDED_IN_ACTIVITY } from "@consts/constants"
import { Row } from "@palette/primitives"
import { radius, spacing } from "@palette/tokens.stylex"
import { ClubDailyRow$key } from "@relay/ClubDailyRow.graphql"
import stylex from "@stylexjs/stylex"

import { numberFormatter } from "../../util/numberFormatter"

const ClubDailyFragment = graphql`
  fragment ClubDailyRow on NakamaGroup {
    name
    id
    slug
    slugID
    ...GroupAvatar
    daily {
      combinedTopScores
      gameTopScores
    }
    members {
      state
      user {
        puzmoUser {
          id
          ...UserDisplay
        }
      }
    }
  }
`

export const ClubDailyRow = (props: { club: ClubDailyRow$key; indentUnplayed?: boolean }) => {
  const clubData = useFragment(ClubDailyFragment, props.club)
  const users = new Map(clubData.members?.map((nUser) => [nUser.user.puzmoUser?.id, nUser.user.puzmoUser]))
  const hasActivity = !!clubData.daily

  return (
    <div {...stylex.props(styles.container, !hasActivity && props.indentUnplayed && styles.indented)}>
      <GroupAvatar size={hasActivity ? "large" : "medium"} group={clubData} />
      <div>
        <BodyText>
          <InternalLink screen="Club" routeOpts={{ groupSlug: clubData.slug, groupSlugID: clubData.slugID }}>
            {clubData.name} — {hasActivity ? `${numberFormatter(clubData.daily.combinedTopScores || 0)}pts` : "No games played yet"}
          </InternalLink>
        </BodyText>
        {hasActivity && (
          <Row style={styles.wrap}>
            {INCLUDED_IN_ACTIVITY.map((gameSlug, i) => {
              const topGame = clubData.daily?.gameTopScores[i]
              const user = topGame && users.get(topGame.uID)

              return (
                <div key={gameSlug} {...stylex.props(styles.gameWrapper)}>
                  <GameIcon
                    gameslug={gameSlug}
                    bg={user ? "var(--theme-a_bg)" : "transparent"}
                    fg="var(--theme-fg"
                    style={{ opacity: user ? 1 : 0.5, height: gameIconDimension }}
                  />

                  {user && (
                    <div key={i} {...stylex.props(styles.avatarWrapper)}>
                      <UserDisplay key={user.id} user={user} mode="avatar-only" size="small" />
                      <div {...stylex.props(styles.crownWrapper)}>
                        <CrownIcon width={30} bgKey="a_puzmo" fgKey="a_bg" />
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </Row>
        )}
      </div>
    </div>
  )
}

const gameIconDimension = 28
const styles = stylex.create({
  container: {
    display: "flex",
    alignItems: "center",
    gap: spacing.medium,
    marginTop: spacing.medium,
  },
  wrap: {
    flexWrap: "wrap",
  },
  gameWrapper: {
    width: 60,
    marginTop: spacing.small,
    position: "relative",
  },
  avatarWrapper: {
    borderRadius: radius.round,
    backgroundColor: "var(--theme-a_bg)",
    position: "absolute",
    left: gameIconDimension / 2,
    top: spacing.xsmall,
  },
  crownWrapper: {
    pointerEvents: "none",
    position: "absolute",
    top: -9,
    right: -11,
    transform: "rotate(21deg)",
  },
  indented: {
    marginLeft: spacing.large,
  },
})
