import { spacing } from "@palette/tokens.stylex"
import { Theme } from "@puzzmo-com/shared/hostAPI"
import { useIsOnline$key } from "@relay/useIsOnline.graphql"
import stylex from "@stylexjs/stylex"

import { useIsMobile } from "../../hooks/useIsMobile"
import { nakamaClient } from "../../lib/nakama/NakamaClient"
import { useIsOnline } from "../../lib/offline/useIsOnline"
import { usePlayGameSelector } from "../../lib/playGameContext/usePlayGameSelector"
import { BodyText } from "../DesignSystem"
import { playGameHeaderContentAreaHeight } from "./playGameConstants"

export function PlayGameNetworkStatusBadge(props: { localState: useIsOnline$key }) {
  // If mobile, don't show 'connected'
  const isMobile = useIsMobile()
  const completed = usePlayGameSelector((s) => s.gameUIState.completeData.isComplete)
  const online = useIsOnline(props.localState)
  const networkState = usePlayGameSelector((s) => s.gameUIState.coopState)
  const isConnected = nakamaClient.isConnected()

  if (completed) return null

  if (online === false) {
    return <SideLabel color="error">Offline</SideLabel>
  }

  if (networkState?.state === "error" || (networkState && !isConnected)) {
    return <SideLabel color="error">Can&apos;t connect</SideLabel>
  }

  if (networkState?.state === "connecting") {
    return <SideLabel color="key">Connecting…</SideLabel>
  }

  if (!isMobile && networkState?.state === "connected") {
    return <SideLabel>Playing together</SideLabel>
  }

  return null
}

const SideLabel = (props: { color?: keyof Theme; children: string }) => {
  const color = props.color || "player"

  return (
    <div {...stylex.props(styles.container(color))}>
      <BodyText bold size="small" customColorKey={"keyFG"}>
        {props.children}
      </BodyText>
    </div>
  )
}

const styles = stylex.create({
  container: (colorKey) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: `var(--theme-${colorKey})`,
    paddingLeft: spacing.medium,
    paddingRight: spacing.medium,
    marginLeft: spacing.large,
    borderRadius: 9999,
    height: playGameHeaderContentAreaHeight,
  }),
})
