import { useCallback, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

import { BodyText, H2, H5, MainContainer } from "@components/DesignSystem"
import { DashedHR } from "@palette/HR"
import { radius, spacing } from "@palette/tokens.stylex"
import { FriendRequestView$key } from "@relay/FriendRequestView.graphql"
import { SocialScreenQuery } from "@relay/SocialScreenQuery.graphql"
import { NakamaFriendRequestView } from "@social/FriendRequestView"
import { FriendsSection } from "@social/friends/FriendsSection"
import { ClubsSection } from "@social/groups/ClubsSection"
import stylex from "@stylexjs/stylex"

import { useViewport } from "../lib/useViewport"
import { useHeaderHeight } from "../navigation/useHeaderHeight"

const invitesWidth = 300

const Query = graphql`
  query SocialScreenQuery {
    currentUser {
      nGroups {
        ...ClubsSection
      }
      nFriends {
        ...FriendsSection
        friends {
          user {
            ...FriendRequestView
            userId
            puzmoUser {
              relationshipToViewer
            }
          }
        }
      }
    }
  }
`

const SocialScreen = () => {
  const [refreshableQueryOptions, setRefreshableQueryOptions] = useState<{
    fetchKey: number
    fetchPolicy: "network-only"
  } | null>(null)
  const { vh } = useViewport()
  const headerHeight = useHeaderHeight()

  const refresh = useCallback(() => {
    // Trigger a re-render of useLazyLoadQuery with the same variables, but an updated fetchKey and fetchPolicy.
    // The new fetchKey will ensure that the query is fully re-evaluated and refetched.
    // The fetchPolicy ensures that we always fetch from the network and skip the local data cache.
    setRefreshableQueryOptions((prev) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "network-only",
    }))
  }, [])

  const data = useLazyLoadQuery<SocialScreenQuery>(Query, {}, refreshableQueryOptions || {})

  if (!data.currentUser) return null

  const friendRequests = (data.currentUser.nFriends?.friends || []).filter(
    (f) => f.user.puzmoUser?.relationshipToViewer === "UserHasSentFriendRequest",
  )

  return (
    <div {...stylex.props(styles.wrapper(vh(100) - headerHeight))}>
      <div {...stylex.props(styles.main)}>
        {!!data.currentUser.nGroups && <ClubsSection userGroups={data.currentUser.nGroups} refreshClubs={refresh} />}
        <DashedHR thick colorKey="a_headerText-a10" stylexStyle={styles.sectionDivider} />
        {!!data.currentUser.nFriends && <FriendsSection friends={data.currentUser.nFriends} />}
      </div>
      <InvitesSection friendRequests={friendRequests} />
    </div>
  )
}

const InvitesSection = (props: { friendRequests: { user: FriendRequestView$key }[] }) => {
  return (
    <div {...stylex.props(styles.invitesSection)}>
      <H5>Pending invitations</H5>
      <div {...stylex.props(styles.invites)}>
        {/* TODO: include club invites in this section. Currently groupInvites is only exposed as part the today page so this probably required API changes */}
        {props.friendRequests.length ? (
          props.friendRequests.map((f, i) => <NakamaFriendRequestView key={i} nUser={f.user} />)
        ) : (
          <BodyText>You have no pending friend or club invitations.</BodyText>
        )}
      </div>
    </div>
  )
}

export default SocialScreen

export const socialSectionHeaderStyles = stylex.create({
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerButtons: {
    display: "flex",
    gap: spacing.medium,
    marginLeft: "auto",
  },
  headerButton: {
    width: 130,
    "@media (max-width: 560px)": {
      width: "auto",
      minWidth: 100,
    },
  },
})

const styles = stylex.create({
  wrapper: (height) => ({
    display: "flex",
    position: "relative",
    minHeight: height,
    paddingHorizontal: spacing.large,
    "@media (max-width: 760px)": {
      flexDirection: "column-reverse",
    },
  }),
  main: {
    flexGrow: 1,
    paddingVertical: spacing.large,
    maxWidth: 1000,
    width: "100%",
    marginHorizontal: "auto",
  },
  sectionDivider: {
    marginTop: spacing.large,
    marginBottom: spacing.large,
  },
  invitesSection: {
    flexShrink: 0,
    padding: spacing.large,
    width: invitesWidth,
    marginLeft: spacing.large,
    borderLeft: "5px solid var(--theme-g_bgDark)",

    "@media (max-width: 760px)": {
      width: "auto",
      paddingHorizontal: spacing.large,
      paddingVertical: spacing.medium,
      marginLeft: 0,
      marginTop: spacing.large,
      border: "1px solid var(--theme-g_bgDark)",
      borderLeft: "1px solid var(--theme-g_bgDark)",
      borderRadius: radius.small,
    },
  },
  invites: {
    marginTop: spacing.large,
  },
})
