import { useRoute } from "wouter"

import { BodyText, InternalLink } from "@components/DesignSystem"
import { Tooltip } from "@palette/Tooltip"
import stylex from "@stylexjs/stylex"

import { useGetUser } from "../../CoreContext"
import { radius, spacing } from "../../palette/tokens.stylex"
import { namedRoutes } from "../LinkingConfiguration"

/**
 * A generic component for header tabs with a name and icon.
 * Handles linking, active state, and narrow display (icon only).
 */
export function AppHeaderTab(props: {
  routeName: keyof typeof namedRoutes
  text: string
  iconComponent: React.ReactNode
  smallWidth?: boolean
}) {
  const user = useGetUser()
  const isLoggedIn = user.type === "user"
  const [isActiveTab] = useRoute(namedRoutes[props.routeName])

  if (isLoggedIn) {
    const tabElem = (
      <InternalLink screen={props.routeName}>
        <div {...stylex.props(styles.container, isActiveTab && styles.active)}>
          {!props.smallWidth && <BodyText customColorKey="alwaysLight">{props.text}</BodyText>}
          {props.iconComponent}
        </div>
      </InternalLink>
    )
    return props.smallWidth ? <Tooltip text={props.text}>{tabElem}</Tooltip> : tabElem
  }

  return null
}

const styles = stylex.create({
  container: {
    display: "flex",
    alignItems: "center",
    gap: spacing.small,
    padding: spacing.medium,
    borderRadius: radius.small,
    backgroundColor: {
      ":hover": "var(--theme-alwaysLight-a10)",
    },
  },
  active: {
    backgroundColor: {
      default: "var(--theme-alwaysLight-a25)",
      ":hover": null,
    },
  },
})
