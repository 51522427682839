import { H3 } from "@components/DesignSystem"
import stylex, { StyleXStyles } from "@stylexjs/stylex"

import { useIsMobile } from "../hooks/useIsMobile"
import { DefaultThemeOverride } from "./DynamicThemeChanger"
import { radius, spacing } from "./tokens.stylex"

const highlightedSection = stylex.create({
  base: {
    backgroundColor: "var(--theme-alwaysDark)",
    borderRadius: radius.medium,
    padding: spacing.medium,
    paddingTop: spacing.large,
    paddingBottom: spacing.large,
    margin: spacing.medium,
  },
  mobile: {
    paddingTop: spacing.medium,
    paddingBottom: spacing.medium,
  },
})

export const HighlightedSection = (props: React.PropsWithChildren<{ title?: React.JSX.Element | string }>) => {
  const isMobile = useIsMobile()

  return (
    <DefaultThemeOverride colorScheme="dark">
      <div className={stylex(highlightedSection.base, isMobile && highlightedSection.mobile)}>
        {props.title && <div>{typeof props.title === "string" ? <H3>{props.title}</H3> : props.title}</div>}
        {props.children}
      </div>
    </DefaultThemeOverride>
  )
}

const columnStyle = stylex.create({
  base: {
    display: "flex",
    flexDirection: "column",
  },
})

export const Column = (props: React.PropsWithChildren<{ style?: StyleXStyles }>) => (
  <div {...stylex.props(columnStyle.base, props.style)}>{props.children}</div>
)

const rowStyle = stylex.create({
  base: {
    display: "flex",
  },
  switchToCol: {
    flexDirection: {
      default: "row",
      "@media (max-width: 560px)": "column",
    },
  },
})

export const Row = (props: React.PropsWithChildren<{ style?: StyleXStyles; useColOnMobile?: boolean }>) => (
  <div {...stylex.props(rowStyle.base, props.useColOnMobile && rowStyle.switchToCol, props.style)}>{props.children}</div>
)
