import { useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

import { BodyText, H2, H3, InternalLink, ThinButton } from "@components/DesignSystem"
import { ModalDisplay } from "@components/ModalDisplay"
import { GroupAvatar } from "@components/groups/GroupAvatar"
import { Column } from "@palette/primitives"
import { radius, spacing } from "@palette/tokens.stylex"
import { ClubsSection$data, ClubsSection$key } from "@relay/ClubsSection.graphql"
import stylex from "@stylexjs/stylex"

import { socialSectionHeaderStyles } from "../../screens/SocialScreen"
import { ClubDailyRow } from "./ClubDailyRow"
import { ClubsSearch } from "./ClubsSearch"
import { CreateClubForm } from "./CreateClubForm"

const ClubsFragment = graphql`
  fragment ClubsSection on NakamaGroupList {
    userGroups {
      group {
        id
        slug
        slugID
        name
        daily {
          combinedTopScores
        }
        ...GroupAvatar
        ...ClubDailyRow
      }
      state
    }
  }
`

export const ClubsSection = (props: { userGroups: ClubsSection$key; refreshClubs: () => void }) => {
  const groups = useFragment(ClubsFragment, props.userGroups)?.userGroups

  const [showCreateClub, setShowCreateClub] = useState(false)
  const [showFindClub, setShowFindClub] = useState(false)
  const closeCreateClub = () => setShowCreateClub(false)
  const closeFindClub = () => setShowFindClub(false)

  const clubsYouAreIn = (groups?.filter(({ state }) => (state === null || state === undefined ? 4 : state) < 3) || []).sort((a, b) => {
    const aHasActivity = !!a.group?.daily
    const bHasActivity = !!b.group?.daily
    // If one has activity and the other doesn't, the one with activity should be first.
    if (aHasActivity !== bHasActivity) {
      return aHasActivity ? -1 : 1
    }
    // Otherwise order alphabetically.
    return (a.group?.name || "").localeCompare(b.group?.name || "")
  })
  const clubsYouAppliedTo = groups?.filter(({ state }) => state === 3) || []

  return (
    <>
      <div id="clubs">
        <div {...stylex.props(socialSectionHeaderStyles.header)}>
          <H2 marginLess>Clubs</H2>
          <div {...stylex.props(socialSectionHeaderStyles.headerButtons)}>
            <ThinButton
              title="Find club"
              onPress={() => setShowFindClub(true)}
              bgKey="key"
              colorKey="keyFG"
              block
              stylexStyle={socialSectionHeaderStyles.headerButton}
            />
            <ThinButton
              title="Create club"
              onPress={() => setShowCreateClub(true)}
              bgKey="key"
              colorKey="keyFG"
              block
              stylexStyle={socialSectionHeaderStyles.headerButton}
            />
          </div>
        </div>

        <div {...stylex.props(styles.clubsList)}>
          {clubsYouAreIn.length > 0 ? (
            clubsYouAreIn.map(
              (userGroup) => userGroup.group && <ClubDailyRow key={userGroup.group.id} club={userGroup.group} indentUnplayed />,
            )
          ) : (
            <BodyText>You are not a member of any clubs yet.</BodyText>
          )}
          {clubsYouAppliedTo.length > 0 && (
            <div {...stylex.props(styles.flex, styles.requestsContainer)}>
              {clubsYouAppliedTo.map((userGroup) => !!userGroup.group && <PendingClub key={userGroup.group.id} club={userGroup.group} />)}
            </div>
          )}
        </div>
      </div>
      <CreateClubModal show={showCreateClub} closeModal={closeCreateClub} refreshClubs={props.refreshClubs} />
      <FindClubModal show={showFindClub} closeModal={closeFindClub} clubsYouAreIn={clubsYouAreIn} />
    </>
  )
}

type Club = Exclude<ClubsSection$data["userGroups"], null | undefined>[number]["group"]
const PendingClub = ({ club }: { club: Club }) => {
  if (!club) return null

  return (
    <InternalLink screen="Club" routeOpts={{ groupSlug: club.slug, groupSlugID: club.slugID }}>
      <div key={club.id} {...stylex.props(styles.requested)}>
        <GroupAvatar size="medium" group={club} noLink />
        <Column>
          <BodyText>{club.name}</BodyText>
          <BodyText size="small" customColorKey="fg-a50">
            Request pending
          </BodyText>
        </Column>
      </div>
    </InternalLink>
  )
}

const CreateClubModal = (props: { show: boolean; closeModal: () => void; refreshClubs: () => void }) => {
  return (
    <ModalDisplay
      show={props.show}
      close={props.closeModal}
      modal={
        <div {...stylex.props(styles.modalBase)}>
          <CreateClubForm onClose={props.closeModal} refreshClubs={props.refreshClubs} />
        </div>
      }
    />
  )
}

const FindClubModal = (props: { show: boolean; closeModal: () => void; clubsYouAreIn: { group?: { id: string } | null }[] }) => {
  return (
    <ModalDisplay
      show={props.show}
      close={props.closeModal}
      modal={
        <div {...stylex.props(styles.modalBase)}>
          <H3>Find a club</H3>
          <br />
          <ClubsSearch clubsYouAreIn={props.clubsYouAreIn} />
        </div>
      }
    />
  )
}

const styles = stylex.create({
  flex: {
    display: "flex",
    alignItems: "center",
    gap: spacing.medium,
  },
  wrap: {
    flexWrap: "wrap",
  },
  clubsList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: spacing.medium,
    paddingVertical: spacing.medium,
  },
  requestsContainer: {
    display: "flex",
    gap: spacing.large,
    flexWrap: "wrap",
    padding: spacing.medium,
    backgroundColor: "var(--theme-a_bgAlt)",
    width: "100%",
    borderRadius: radius.small,
  },
  requested: {
    display: "flex",
    alignItems: "center",
    gap: spacing.medium,
  },
  modalBase: {
    padding: spacing.large,
  },
})
