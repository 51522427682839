import stylex from "@stylexjs/stylex"

export type AvatarSize = keyof typeof sizeStyles

/** A generic avatar component which has a ring around a person, comes with a square variant */
export const Avatar = (props: { size: AvatarSize; square?: boolean; borderColor?: string; imgSrc: string; alt: string }) => {
  return (
    <div
      {...stylex.props(
        s.outer,
        props.size === "tiny" && sizeStyles.tiny,
        props.size === "small" && sizeStyles.small,
        props.size === "medium" && sizeStyles.medium,
        props.size === "large" && sizeStyles.large,
        props.size === "xlarge" && sizeStyles.xlarge,
        s.circle,
        props.square && s.square,
      )}
      style={{
        borderColor: props.borderColor || "transparent",
      }}
    >
      <img
        src={props.imgSrc}
        alt={props.alt}
        {...stylex.props(
          props.size === "tiny" && innerSizes.tiny,
          props.size === "small" && innerSizes.small,
          props.size === "medium" && innerSizes.medium,
          props.size === "large" && innerSizes.large,
          props.size === "xlarge" && innerSizes.xlarge,
          !props.square && s.circle,
        )}
      />
    </div>
  )
}

const sizeStyles = stylex.create({
  tiny: { width: 12, height: 12, borderWidth: 1 },
  small: { width: 24, height: 24, borderWidth: 2 },
  medium: { width: 40, height: 40, borderWidth: 2 },
  large: { width: 64, height: 64, borderWidth: 2 },
  xlarge: { width: 128, height: 128, borderWidth: 2 },
})

const innerSizes = stylex.create({
  tiny: { width: 8, height: 8 },
  small: { width: 16, height: 16 },
  medium: { width: 34, height: 34 },
  large: { width: 54, height: 54 },
  xlarge: { width: 120, height: 120 },
})

const s = stylex.create({
  outer: {
    position: "relative",
    borderStyle: "solid",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  circle: { borderRadius: 9999 },
  square: { borderRadius: 0 },
})
